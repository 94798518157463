import { List, Tabs, Typography, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import { beautifyKey } from "../../../../helpers/text";

const { TabPane } = Tabs;

const { Text } = Typography;
export default function IdCardData({ data = [], loading, handleEdit }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState([...data]);
  const [editableIndex, setEditableIndex] = useState(null);
  useEffect(() => {
    setEditedData([...data]);
  }, [data]);

  const handleEditDoubleClick = (index) => {
    setEditableIndex(index);
    setIsEditing(true);
  };

  const handleInputChange = (index, e) => {
    const newData = [...editedData];
    newData[index].value = e.target.value;
    setEditedData(newData);
  };

  const handleSave = () => {
    handleEdit(editedData);
    setIsEditing(false);
    setEditableIndex(null);
  };

  if (!data || loading) return <Loading height="25vh" />;

  return (
    <div style={{ padding: 10 }}>
      <List
        grid={{ gutter: 16, column: 2 }}
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item
            key={index}
            onDoubleClick={() => handleEditDoubleClick(index)}
          >
            <Text strong>{beautifyKey( item.key)}</Text>:{" "}
            {editableIndex === index && isEditing ? (
              <>
                <Input
                  value={editedData[index]?.value}
                  onChange={(e) => handleInputChange(index, e)}
                />
                <Button onClick={handleSave}>Save</Button>
              </>
            ) : (
              <span>{item.value}</span>
            )}
          </List.Item>
        )}
      />
    </div>
  );
}

export const make = (data = {}) => {
  // make the id card data from the data junk
  const additionalInfo = data.ADDITIONAL_INFORMATION ?? {};

  const addressParts = [];
  if (data.ADDRESS) {
    addressParts.push(data.ADDRESS);
  }
  if (additionalInfo.CITY_IN_ADDRESS) {
    addressParts.push(additionalInfo.CITY_IN_ADDRESS);
  }

  const listItems = [
    { key: "name", value: data.FIRST_NAME + " " + data.LAST_NAME },
    { key: "date_of_birth", value: data.DATE_OF_BIRTH },
    {
      key: "address",
      value: addressParts.join(", "),
    },
    {
      key: "city",
      value: additionalInfo.CITY_IN_ADDRESS,
    },
    {
      key: "state",
      value: additionalInfo.STATE_NAME,
    },
    {
      key: "zip",
      value: data.ZIP_CODE_IN_ADDRESS,
    },
    { key: "id_type", value: data.ID_TYPE },
    { key: "id_number", value: additionalInfo.DOCUMENT_NUMBER },
    { key: "class", value: additionalInfo.CLASS },
    { key: "date_of_issue", value: additionalInfo.DATE_OF_ISSUE },
    { key: "expiration_date", value: additionalInfo.EXPIRATION_DATE },
    { key: "endorsements", value: additionalInfo.ENDORSEMENTS },
    { key: "restrictions", value: additionalInfo.RESTRICTIONS },
    {
      key: "is_veteran",
      value: additionalInfo.VETERAN,
    },
  ];

  return listItems.map((item) => {
    if (item.value === undefined || item.value === null)
      return { ...item, value: "" };
    else return item;
  });
};
