import React, { useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";
import Loading from "../../../../components/Loading/Loading";
// import fillPdfForm from "./generateSNAPPDF"

import { fullDocConfig, systemgeneratedanswers } from "../config/document.config";
import { getCurrentDate } from "../../../../helpers/time";

function textToBase64Image(text) {
  // Create a canvas element
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set a default width and height
  canvas.width = 300;
  canvas.height = 100;

  // Set the font and text color
  context.font = "30px sans-serif";
  context.fillStyle = "black";

  // Draw the text on the canvas
  context.fillText(text, 10, 50); // (x, y) position to start drawing

  // Convert the canvas to a base64 image
  return canvas.toDataURL("image/png");
}

async function addSignature(
  pdfDoc,
  base64Image,
  useThesePositions = {
    x0: 190.69099998474121,
    y0: 402.656982421875,
    x1: 250.6919860839844,
    y1: 430.093994140625,
  }
) {
  const imageBytes = Uint8Array.from(atob(base64Image.split(",")[1]), (c) => c.charCodeAt(0));
  let image;
  if (base64Image.indexOf("image/jpeg") !== -1) {
    image = await pdfDoc.embedJpg(imageBytes);
  } else if (base64Image.indexOf("image/png") !== -1) {
    image = await pdfDoc.embedPng(imageBytes);
  } else {
    throw new Error("Unsupported image type");
  }

  const signature = {
    type: "Image",
    position: useThesePositions,
    page: 17,
  };

  const { x0, y0, x1, y1 } = signature.position;
  const width = x1 - x0;
  const height = y1 - y0;

  const pageIndex = signature.page - 1;
  const page = pdfDoc.getPages()[pageIndex];

  if (!page) {
    throw new Error(`Page number ${signature.page} does not exist in the document.`);
  }

  const yPosition = page.getHeight() - y1;

  page.drawImage(image, {
    x: x0,
    y: yPosition,
    width: width,
    height: height,
  });
}

export const fillPdfForm = async (user) => {
  const pdfPath = `${process.env.PUBLIC_URL}/assets/snap.pdf`;

  const arrayBuffer = await fetch(pdfPath).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  if (user["signature"]) {
    await addSignature(pdfDoc, user["signature"]);
  } else {
    // use name
    // quick fix
    await addSignature(pdfDoc, textToBase64Image(user["name"]?.value ?? user["name"]), { x0: 160.69099998474121, y0: 385.656982421875, x1: 250.6919860839844, y1: 450.093994140625 });
  }
  // adding date
  await addSignature(pdfDoc, textToBase64Image(getCurrentDate()), { x0: 100.69099998474121, y0: 425.656982421875, x1: 200.6919860839844, y1: 445.093994140625 });

  const form = pdfDoc.getForm();

  const fields = {};
  form.getFields().map((field) => {
    // console.log(field)

    const name = field.getName();
    fields[name] = field;
  });
  const config = [...systemgeneratedanswers(user), ...fullDocConfig];
  const mapped_items = [];

  config.forEach((itemx) => {
    const item = JSON.parse(JSON.stringify(itemx));

    item.value = user?.[item.id]?.value ?? item.value;

    if (typeof itemx.snap_id === "function") {
      item.snap_id = itemx.snap_id(0, item.value);
    }

    mapped_items.push(item);
    if (item?.value === false) return;
    itemx.more?.questions.forEach((subitemx, i) => {
      console.log("🍉MORE ENTRIES", user?.[item.id]);
      const moreEntries = JSON.parse(user?.[item.id]?.moreEntries ?? user?.[item.id]?.value?.moreEntries ?? "[]");
      moreEntries?.forEach((entries, j) => {
        const entry = entries[i];
        const subitem = JSON.parse(JSON.stringify(subitemx));
        subitem.value = entry?.value;
        // console.log("subitemx", subitem, entries, entry);
        if (typeof subitemx.snap_id === "function") {
          subitem.snap_id = subitemx.snap_id(j, subitem.value, entries);
          mapped_items.push(subitem);
        } else {
          subitem.snap_id = subitemx.snap_id;
          mapped_items.push(subitem);
        }
      });
    });
  });
  mapped_items.map((item) => {
    const value = item.value;
    const snap_idx = item.snap_id ?? item.question;
    const snap_ids = Array.isArray(snap_idx) ? snap_idx : [snap_idx];
    if (snap_ids.length > 1) console.log("snapids", snap_ids);
    // console.log(snap_ids)
    snap_ids.forEach((snap_id) => {
      const field = fields[snap_id];
      // console.log("==>", snap_id, item, field, value);
      if (!snap_id || !field) return;
      try {
        if (["text", "date"].includes(item.type)) {
          if (item.id === "disability") {
            // console.log("dis", snap_id, field);
          }
          if (field.check) field.check();
          if (field.setText) field.setText(value);
        } else if (item.type === "boolean") {
          field.select(value === true ? "Yes" : "No");
        } else if (item.type === "select") {
          field.check();
        } else if (item.type === "multi-select") {
          field.check();
        }
      } catch (err) {
        console.log(err);
      }
    });
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  console.log(user);

  return blob;
};
