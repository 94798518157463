import { splitDate } from "../../helpers/datetime";
import { flattenJson } from "../../helpers/obj";
import { splitName } from "../Users/SNAP/generateSnapPdf/get";

export const mapDataToTexasLifeline = (dataComplete) => {
  // used for auto filling
  const data = flattenJson(dataComplete);

  const config = {
    applicantFirstName: splitName(data?.name)?.first_name,
    applicantMiddleName: splitName(data?.name)?.middle_name,
    applicantLastName: splitName(data?.name)?.last_name,
    applicantSsn: data?.id_number,
    dobYear: splitDate(data?.date_of_birth)?.year,
    dobMonth: splitDate(data?.date_of_birth)?.month,
    dobDay: splitDate(data?.date_of_birth)?.day,
    applicantEmail: data?.applicantEmail,

    // Address

    serviceAddressState: "44", // texas,
    serviceAddressCity: data?.city,
    serviceAddressStreet1: data?.address,
    serviceAddressZipCode: data?.zip,
    mailingAddressState: "44", // texas,
    mailingAddressCity: data?.city,
    mailingAddressStreet1: data?.address,
    mailingAddressZipCode: data?.zip,
  };
  console.log("mapping data to lifeline", config);
  return config;
};
