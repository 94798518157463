import React, { useContext } from "react";

const MainContext = React.createContext();

export const mainContextConfig = {
  loc: {
    tx: {
      title: "Texas",
    },
    ma: {
      title: "Massachusetts",
    },
  },
};

export default MainContext;
