export const allRelations = [
  // Immediate Family
  "Father",
  "Mother",
  "Son",
  "Daughter",
  "Brother",
  "Sister",
  "Husband",
  "Wife",

  // Extended Family
  "Grandfather",
  "Grandmother",
  "Grandson",
  "Granddaughter",
  "Uncle",
  "Aunt",
  "Nephew",
  "Niece",
  "Cousin (Male)",
  "Cousin (Female)",
  "Father-in-law",
  "Mother-in-law",
  "Son-in-law",
  "Daughter-in-law",
  "Brother-in-law",
  "Sister-in-law",

  // Non-familial Relationships
  "Friend",
  "Colleague",
  "Mentor",
  "Mentee",
  "Partner",
  "Significant Other",
];
