import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Auth/AuthProvider";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./Auth/ProtectedRoute";
import NotFound from "./components/NotFound/NotFound";
import User from "./pages/Users/User";
import Instructions from "./pages/Instructions";
import Testing from "./pages/testing";
import Config from "./pages/config";
import WicTexasForm from "./pages/forms/wic_texas/WicTexasform";
import MassSNAP from "./pages/forms/snap/mass/MassSNAP";

const routes = [
  {
    path: "/login",
    element: Login,
    protected: false,
  },
  {
    path: "/",
    element: Home,
  },
  {
    path: "user",
    element: User,
  },
  {
    path: "*", // Wildcard route for 404 page
    element: NotFound,
    protected: false,
  },
  {
    path: "instructions",
    element: Instructions,
  },
  {
    path: "testing",
    element: Testing,
  },
  {
    path: "config",
    element: Config,
  },
  {
    path: "form/wic_texas",
    element: WicTexasForm,
    protected: false,
  },
  {
    path: "form/snap/mass",
    element: MassSNAP,
    protected: false,
  },
];

function AppRoutes() {
  return (
    <AuthProvider>
      <Routes>
        {routes.map(({ path, element: Component, protected: isProtected = true }, index) => (
          <Route
            key={index}
            path={path}
            element={
              isProtected ? (
                <ProtectedRoute>
                  <Component />
                </ProtectedRoute>
              ) : (
                <Component />
              )
            }
          />
        ))}
      </Routes>
    </AuthProvider>
  );
}

export default AppRoutes;
