import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import Loading from "../components/Loading/Loading";

const ProtectedRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    // Optionally, return a loading indicator or null while checking auth state
    return <Loading />; // Or simply return null to show nothing
  }

  if (!currentUser) {
    // User is not logged in, redirect to login page
    return <Navigate to={`/login?from=${location.pathname}`} />;
  }

  return children;
};
export default ProtectedRoute;
