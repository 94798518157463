export function addOrReplaceQueryParam(key, value) {
  if (value === null || value === undefined) return;
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Check if the key is already present in the URL
  if (urlSearchParams.has(key)) {
    // If key is present, replace the existing value
    urlSearchParams.set(key, value);
  } else {
    // If key is not present, add it
    urlSearchParams.append(key, value);
  }

  const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}${window.location.hash}`;
  window.history.replaceState(null, "", newUrl);
}

export function getAllParamsFromLocation() {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const paramsObject = {};

  for (const [paramName, paramValue] of urlSearchParams) {
    paramsObject[paramName] = paramValue;
  }

  return paramsObject;
}

export function getBaseUrl(fullUrl = window.location.href) {
  const url = new URL(fullUrl);
  return url.origin;
}

export function appendQueryParams(url, params) {
  const paramString = new URLSearchParams(params).toString();
  if (url.includes("?")) {
    return url + "&" + paramString;
  } else {
    return url + "?" + paramString;
  }
}
