import moment from "moment";
import { cleanObject, flattenJson } from "../../helpers/obj";
import { splitName } from "../Users/SNAP/generateSnapPdf/get";
import { timeFormat } from "../Users/SNAP/config/document.config";

export function configurateMedicareSavingsData(data) {
  /**
   * Data should be list of objects like this [{id, value}]
   * Data will be converted to Object.
   * Using this configure function {name:()=>form1_1+form2_1}, it will take id from the data and then find what is equal to form1_1 and form2_1 and apply the function
   */

  // Convert the list of objects to a key-value pair object
  const d = data.reduce((obj, item) => {
    obj[item.id] = item.value;
    return obj;
  }, {});

  // Define the configure function with the desired calculations
  const configure = {
    name: () => d["form5_5"] + " " + d["form4_5"],
    date_of_birth: () => `${d["form16_5"]}/${d["form17_5"]}/${d["form18_5"]}`,
    address: () => d["form6_5"] + " " + d["form7_5"] + " " + d["form8_5"],
    email: () => d["form5_1"],
    phone: () => d["form21_5"] + d["form22_5"],
    ssn: () => d["form26_5"],
    zip: () => d["form13_5"],
    city: () => d["form7_5"],
    state: () => d["form8_5"],
    medicare_uploaded: () => true,

    // spouse's

    spouse_name: () => d["form29_5"] + " " + d["form28_5"],
    spouse_date_of_birth: () => `${d["form40_5"]}/${d["form41_5"]}/${d["form42_5"]}`,
    spouse_address: () => d["form30_5"] + " " + d["form31_5"] + " " + d["form32_5"],
    spouse_email: () => d["form29_1"],
    spouse_phone: () => d["form45_5"] + d["form46_5"],
    spouse_ssn: () => d["form50_5"],
    spouse_zip: () => d["form37_5"],
    spouse_city: () => d["form31_5"],
    spouse_state: () => d["form32_5"],
  };

  // Apply the configure function to the dataObject
  const result = {};
  for (const key in configure) {
    if (typeof configure[key] === "function") {
      result[key] = configure[key]();
    }
  }

  console.log("Configure result: ", result);

  return result;
}

export function mapDataToMedicare(dataComplete) {
  const data = flattenJson(dataComplete);

  const config = {
    "//*[@id='form5_5']": splitName(data?.name)?.first_name,
    "//*[@id='form4_5']": splitName(data?.name)?.last_name,
    "//*[@id='form16_5']": moment(data?.date_of_birth, timeFormat).format("DD-MM-YYYY").split("-")[0],
    "//*[@id='form17_5']": moment(data?.date_of_birth, timeFormat).format("DD-MM-YYYY").split("-")[1],
    "//*[@id='form18_5']": moment(data?.date_of_birth, timeFormat).format("DD-MM-YYYY").split("-")[2],
    // "//*[@id='form38_1']": moment().format("DD-MM-YYYY"),

    "//*[@id='form6_5']": data?.address,
    "//*[@id='form13_5']": data?.zip,
    "//*[@id='form7_5']": data?.city,
    "//*[@id='form8_5']": data?.state,
  };

  return encodeURIComponent(JSON.stringify(cleanObject(config)));
}

// // Example usage:
// const data = [
//   { id: "form1_1", value: 10 },
//   { id: "form2_1", value: 20 },
// ];

// console.log(configurateKinderWaitData(data)); // { name: 30 }
