import { ConfigProvider } from "antd";
import React, { useEffect, useState } from "react";
import MainContext from "./contexts/mainContext";

export default function AppConfig({ children }) {
  const [loc, setLoc] = useState("ma"); // Default value for loc

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("tx-")) {
      setLoc("tx");
    } else {
      setLoc("ma");
    }
  }, []); // Empty dependency array ensures this runs only once

  return (
    <MainContext.Provider value={{ loc }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#47B0F8",
          },
        }}
      >
        {children}
      </ConfigProvider>
    </MainContext.Provider>
  );
}
