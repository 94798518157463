import React, { useEffect, useState } from "react";

const FileViewer = ({ file, fileType }) => {
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileUrl(e.target.result);
      };

      if (fileType === "pdf") {
        reader.readAsDataURL(file);
      } else {
        reader.readAsDataURL(file);
      }
    }
  }, [file, fileType]);

  if (!file) return null;

  return (
    <div style={{ marginTop: "20px", textAlign: "center" }}>
      {fileType === "pdf" ? (
        <iframe src={fileUrl} width="100%" height="500px" title="PDF Viewer" style={{ border: "none" }}></iframe>
      ) : (
        <img src={fileUrl} alt="Preview" style={{ maxWidth: "100%", maxHeight: "500px" }} />
      )}
    </div>
  );
};

export default FileViewer;
