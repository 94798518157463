import React, { createContext, useState, useEffect } from 'react';
import { db } from '../firebase/config';
import { collection, getDocs } from 'firebase/firestore';

const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  const [configData, setConfigData] = useState([]);

  useEffect(() => {
    console.log('FirebaseProvider mounted');
    const fetchData = async () => {
      console.log('Fetching data...');
      try {
        const querySnapshot = await getDocs(collection(db, 'config'));
        const dataList = querySnapshot.docs.map(doc => doc.data());
        setConfigData(dataList);
        console.log('Fetched config data:', dataList);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <ConfigContext.Provider value={configData}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigProvider };
