import moment from "moment";

const DATE_FORMAT = "MM/DD/YYYY";

const formatTime = (timeStr, initialFormat = "MM/DD/YYYY") => {
  return timeStr;
  const date = moment(timeStr, initialFormat).format(DATE_FORMAT);
  return date !== "Invalid date" ? date : "-";
};

export function splitDate(date, format = "DD-MM-YYYY") {
  const momentDate = moment(date, format);
  const day = momentDate.date();
  const month = momentDate.format("M") - 1; // Month id
  const year = momentDate.year();

  return {
    day: day,
    month: month,
    year: year,
  };
}

export default formatTime;
export { DATE_FORMAT };
