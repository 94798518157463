import moment from "moment";
import { timeFormat } from "../pages/Users/SNAP/config/document.config";

export function getTimeBasedGreeting() {
  const now = new Date();
  const hour = now.getHours();

  if (hour < 12) {
    return "Good morning!";
  } else if (hour >= 12 && hour < 17) {
    return "Good afternoon!";
  } else {
    return "Good evening!";
  }
}
export function getMonthName(index) {
  return moment().month(index).format("MMMM");
}

export function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const day = String(today.getDate()).padStart(2, "0");

  // Returns the date in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
}

export function convertToExcelDate(jsDateNumber) {
  return new Date(jsDateNumber).toLocaleDateString("en-US");
}

export function calculateAge(dateOfBirth) {
  const age = moment().diff(moment(dateOfBirth, timeFormat), "years");
  return typeof age === "number" ? age : "";
}

export function normalizeDate(dateInput, returnError = true) {
  // List of common date formats to parse
  console.log("Normalizing date:", dateInput);
  const dateFormats = ["MM/DD/YYYY", "DD/MM/YYYY", "MMMM D, YYYY", "D MMMM YYYY", "D MMM YYYY", "YYYY-MM-DD", "DD-MM-YYYY", "MM-DD-YYYY", "YYYY/MM/DD", "MM/DD/YY", "DD/MM/YY"];

  let validDate = null;

  // Loop through the formats and find the first valid date
  for (let format of dateFormats) {
    const date = moment(dateInput, format, true);
    if (date.isValid()) {
      validDate = date;
      break;
    }
  }

  if (validDate) {
    // Format the date to DD-MM-YYYY
    return validDate.format("DD-MM-YYYY");
  } else {
    console.log("Date normalization error: Invalid date format.");
    if (returnError) {
      throw new Error("Invalid date format");
    } else {
      return null;
    }
  }
}
