import React from "react";
import { message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";

const ReactDropZoneMe = ({ handleImageUpload }) => {
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      handleImageUpload({ target: { files: acceptedFiles } });
    } else {
      message.error("File not accepted. Please try again.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  return (
    <div
      {...getRootProps()}
      style={{
        width: "100%",
        border: "1px dashed #d9d9d9",
        padding: "10px",
        textAlign: "center",
      }}
    >
      <input {...getInputProps()} style={{ display: "none" }} />
      <p className="ant-upload-drag-icon">
        <InboxOutlined style={{ fontSize: "100px" }} />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">Support for a single image file.</p>
    </div>
  );
};

export default ReactDropZoneMe;
