import React, { useEffect, useState } from "react";
import { Button, Drawer, Space } from "antd";
import Summary from "../Summary/HomePageSummary";
import { flattenJson } from "../../helpers/obj";
import { IoBookOutline } from "react-icons/io5";

const UserSummaryDrawer = ({ users }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const summaryData = users
    ? users.map((user) => {
        const newUser = JSON.parse(JSON.stringify(flattenJson(user)));
        delete newUser["signature"];
        delete newUser["id"];
        return newUser;
      })
    : [];

  return (
    <div>
      <Button onClick={showDrawer}>Summary</Button>
      <Drawer title="User Summary" placement="right" onClose={closeDrawer} open={drawerVisible} width={"100%"}>
        <Summary data={summaryData} />
      </Drawer>
    </div>
  );
};

export default UserSummaryDrawer;
