import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
const Important = ({ children, active = true }) => {
  if (active)
    return (
      <div>
        {" "}
        <FaArrowRight />
        {children} <FaArrowLeft />
      </div>
    );

  return children;
};

export default Important;
