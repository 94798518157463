import moment from "moment";
import { cleanObject, flattenJson } from "../../helpers/obj";
import { splitName } from "../Users/SNAP/generateSnapPdf/get";
import { timeFormat } from "../Users/SNAP/config/document.config";

export function configurateKinderWaitData(data) {
  /**
   * Data should be list of objects like this [{id, value}]
   * Data will be converted to Object.
   * Using this configure function {name:()=>form1_1+form2_1}, it will take id from the data and then find what is equal to form1_1 and form2_1 and apply the function
   */

  // Convert the list of objects to a key-value pair object
  const d = data.reduce((obj, item) => {
    obj[item.id] = item.value;
    return obj;
  }, {});

  // Define the configure function with the desired calculations
  const configure = {
    name: () => d["form1_1"] + " " + d["form6_1"],
    date_of_birth: () => d["form2_1"],
    address: () => d["form3_1"] + " " + d["form7_1"],
    email: () => d["form5_1"],
    phone: () => d["form10_1"],
    ssn: () => d["form30_1"],
    zip: () => d["form9_1"],
    city: () => d["form8_1"],
    state: () => d["form4_1"],
    kinderwait_uploaded: () => true,
  };

  // Apply the configure function to the dataObject
  const result = {};
  for (const key in configure) {
    if (typeof configure[key] === "function") {
      result[key] = configure[key]();
    }
  }

  return result;
}

export function mapDataToKinderWait(dataComplete) {
  const data = flattenJson(dataComplete);

  const config = {
    "//*[@id='form1_1']": splitName(data?.name)?.first_name,
    "//*[@id='form6_1']": splitName(data?.name)?.last_name,
    "//*[@id='form2_1']": moment(data?.date_of_birth, timeFormat).format("DD-MM-YYYY"),
    "//*[@id='form38_1']": moment().format("DD-MM-YYYY"),

    "//*[@id='form5_1']": data?.email,
    "//*[@id='form10_1']": data?.phone,

    "//*[@id='form9_1']": data?.zip,
    "//*[@id='form3_1']": data?.address,
    "//*[@id='form8_1']": data?.city,

    "//*[@id='form4_1']": "Massachusetts",
  };

  return encodeURIComponent(JSON.stringify(cleanObject(config)));
}

// // Example usage:
// const data = [
//   { id: "form1_1", value: 10 },
//   { id: "form2_1", value: 20 },
// ];

// console.log(configurateKinderWaitData(data)); // { name: 30 }
