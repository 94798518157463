import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, message } from "antd";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

const libraries = ["places"];

const LocationAutocomplete = ({ onComplete, onInput }) => {
  const [searchBox, setSearchBox] = useState(null);
  const [address, setAddress] = useState("");

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAZtnOHWjNhBsCJofd76WxF5bKLXskKhyk",
    libraries,
  });

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  useEffect(() => {
    onInput(address);
  }, [address, onInput]);

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      const location = place.geometry.location;

      const addressComponents = place.address_components.reduce((acc, component) => {
        const types = component.types;
        types.forEach((type) => {
          acc[type] = component.long_name;
        });
        return acc;
      }, {});

      const city = addressComponents.locality || addressComponents.sublocality || "";
      const zip = addressComponents.postal_code || "";
      const county = addressComponents.administrative_area_level_2 ? addressComponents.administrative_area_level_2.replace(" County", "") : "";
      const state = addressComponents.administrative_area_level_1 || "";
      const country = addressComponents.country || "";

      if (country === "United States") {
        setAddress(place.formatted_address);
        onInput(place.formatted_address);

        const coords = {
          lat: location.lat(),
          lng: location.lng(),
        };

        const details = addressComponents;

        onComplete(place.formatted_address, city, zip, county, coords, details);
      } else {
        message.warning("Address is not in the United States.");
        console.log("Address is not in the United States or not a complete home address");
      }
    }
  };

  const options = {
    componentRestrictions: { country: "us" },
    types: ["address"],
  };

  const texasBounds = {
    north: 36.500704,
    south: 25.837377,
    west: -106.645646,
    east: -93.508292,
  };

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged} bounds={texasBounds} options={options}>
      <Input size="large" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Search for a location" />
    </StandaloneSearchBox>
  );
};

LocationAutocomplete.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
};

export default LocationAutocomplete;
