import { splitDate } from "../../helpers/datetime";
import { cleanObject, flattenJson } from "../../helpers/obj";
import { getMonthName } from "../../helpers/time";
import { splitName } from "../Users/SNAP/generateSnapPdf/get";

export function configurateDTAConnect(data) {
  /**
   * Data should be list of objects like this [{id, value}]
   * Data will be converted to Object.
   * Using this configure function {name:()=>form1_1+form2_1}, it will take id from the data and then find what is equal to form1_1 and form2_1 and apply the function
   */

  // Convert the list of objects to a key-value pair object
  const d = data.reduce((obj, item) => {
    obj[item.id] = item.value;
    return obj;
  }, {});

  // Define the configure function with the desired calculations
  const configure = {
    name: () => d["id-11-1"] + " " + d["id-14-1"] + " " + d["id-17-1"],
    // date_of_birth: () => d["id-43-1"],
    address: () => d["id-35-1"] + " " + d["id-35-2"],
    email: () => d["id-43-1"],
    phone: () => d["id-40-1"],
    ssn: () => d["id-27-1"],
    zip: () => d["id-35-2"],
    city: () => d["id-35-3"],
    state: () => "MA",
    dta_uploaded: () => true,
  };

  // Apply the configure function to the dataObject
  const result = {};
  for (const key in configure) {
    if (typeof configure[key] === "function") {
      result[key] = configure[key]();
    }
  }

  return result;
}

export function mapDataToDTA(dataComplete) {
  const data = flattenJson(dataComplete);

  const config = {
    "//*[@name='firstName']": splitName(data?.name)?.first_name,
    "//*[@name='lastName']": splitName(data?.name)?.last_name,
    "//*[@name='phoneNumber']": data?.phone,
    "(//*[@name='address'])[1]": data?.address,
    "(//*[@name='address'])[2]": data?.zip,
    "(//*[@name='address'])[3]": data?.city,
    "//*[@name='emailAddress']": data?.email,
    // "(//*[@name='dateOfBirth'])[1]": splitDate(data?.date_of_birth).day,
    // "(//*[@name='dateOfBirth'])[2]": getMonthName(splitDate(data?.date_of_birth).month),
    // "(//*[@name='dateOfBirth'])[3]": splitDate(data?.date_of_birth).year,
    "(//*[@name='language-preference'])[1]": "English",
  };

  return encodeURIComponent(JSON.stringify(cleanObject(config)));
}
