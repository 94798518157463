import { cleanObject, flattenJson } from "../../helpers/obj";
import { splitName } from "../Users/SNAP/generateSnapPdf/get";

export function mapDataToWicTexas(dataComplete) {
  const data = flattenJson(dataComplete);

  const config = {
    "(//input[@type='text'])[1]": splitName(data?.name)?.first_name,
    "(//input[@type='text'])[2]": splitName(data?.name)?.last_name,
    "(//input[@type='text'])[3]": data?.address,
    "(//input[@type='text'])[4]": data?.city,
    "(//input[@type='text'])[6]": data?.email,
    "(//input[@type='TEXT'])[1]": data?.zip,
    "(//input[@type='TEXT'])[2]": data?.phone,
  };

  return encodeURIComponent(JSON.stringify(cleanObject(config)));
}
