import { Button, Collapse, Image, Input, Modal, message } from "antd";
import React, { useState } from "react";
import { BiDownload } from "react-icons/bi";
import isAdmin from "../../firebase/users/config";
import { convertDataToExcelData, generateExcelLocally } from "./generateExcel";
import linkType from "./image.png";

const DownloadExcel = ({ data, buttonStyling }) => {
  // Collapse Configuration

  const [isModalOpen, setIsModalOpen] = useState(false);
  //   const [canUploadSheets, setcanUploadSheets] = useState(false);
  const [sheetID, setSheetID] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const downloadFile = () => {
    generateExcelLocally(data);
    setIsModalOpen(false);
  };

  const updateSheets = () => {
    // const id = ""
    const sheet_data = convertDataToExcelData(data);
    console.log(sheet_data);

    // return 0

    fetch("https://alistermartin.pythonanywhere.com/gsheet/update_sheet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sheet_id: sheetID,
        data: sheet_data,
      }),
    })
      .then(message.success("Data Uploaded to sheets"))
      .catch(message.error("error uploading data"));

    setSheetID("");
    setIsModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: "Download Excel Locally",
      children: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "16px",
              padding: "0 10px",
            }}
          >
            <h4>Export as Excel file </h4>
            <Button type="primary" onClick={downloadFile}>
              Download
            </Button>
          </div>
        </>
      ),
    },
    // {
    //   key: "2",
    //   label: "Upload on google Sheets",
    //   children: (
    //     <>
    //       <h5>
    //         {" "}
    //         Go to <a href="https:/docs.google.com"> google sheets </a> and
    //         create a new sheet. Now share with
    //         "gsheet@harvardlearning-demo.iam.gserviceaccount.com" and give it
    //         the Editor access
    //       </h5>
    //       <h5>Copy and paste the sheet id from the url below</h5>
    //       <Image width={"99%"} src={linkType} />
    //       <Input
    //         style={{ marginTop: "40px" }}
    //         onChange={(e) => {
    //           setSheetID(e.target.value);
    //         }}
    //         placeholder="Sheet ID"
    //       />
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "center",
    //           marginTop: "16px",
    //           padding: "0 10px",
    //         }}
    //       >
    //         <h4>Upload to sheets</h4>
    //         <Button type="primary" disabled={!sheetID} onClick={updateSheets}>
    //           Upload Sheets
    //         </Button>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  if (!isAdmin()) return <></>;
  return (
    <>
      <Button
        style={{
          ...buttonStyling,
        }}
        className="flexbtn"
        type="dashed"
        onClick={showModal}
      >
        Export
        <BiDownload />
      </Button>
      <Modal
        title="Download Data"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "16px",
            padding: "0 10px",
          }}
        >
          <Collapse
            accordion
            items={items}
            defaultActiveKey={["1"]}
            size="large"
            style={{ width: "99%" }}
          />
        </div>
      </Modal>
    </>
  );
};
export default DownloadExcel;
