import { Alert, Modal, Select } from "antd";
import React from "react";
import FormQuestions from "../SNAP/Form";
import "./style.css";
const { Option } = Select;

export const FormModal = ({ open, setOpen, type, user, updateUser }) => {
  const save = async (data) => {
    const to_obj = {};
    data.forEach((item) => {
      to_obj[item.id] = item;
    });
    await updateUser(to_obj);
  };
  const getInfo = () => {
    switch (type) {
      case "partial_liheap":
        return "Please complete the following details, if the person doesn't have time to stay!";
      case "lifeline":
        return "Please complete the following to start your Lifeline application.";
      case "wic":
        return "Please complete the questions here if the the tampermonkey extension is not working for you in external application.";
      default:
        return "Please fill the following details to complete the SNAP application.";
    }
  };
  const titleMap = {
    partial_liheap: "LIHEAP partial application",
    snap: "SNAP Application - Additional questions",
    lifeline: "Lifeline Program Application",
    fullsnap: "Complete SNAP Application",
    wic: "WIC - Women, Infants and Children Program (Online Form)",
  };
  return (
    <Modal title={`Application (${titleMap[type]})`} open={open} width={"77%"} onOk={save} okText="Save" footer={false} onCancel={() => setOpen(false)}>
      <div style={{ marginTop: 20, marginBottom: 20 }}>{getInfo()}</div>
      {/* <RegistrationForm /> */}
      {type==="fullsnap"&&!user.signature&&<Alert showIcon type="warning" message="Attention: Signature required" description="Signature is required in this application. Please add it as well in the dashboard, after completing the application."/>}
      <FormQuestions onCancel={() => setOpen(false)} type={type} user={user} save={save} />
    </Modal>
  );
};
