import React from "react";
import { Timeline } from "antd";
import { flattenJson } from "../../../helpers/obj";
import { applicationConfigs } from "../../Home/home.config";
import moment from "moment";
import { timeFormat } from "../SNAP/config/document.config";

const TimelineLogs = ({ user }) => {
  const userflat = flattenJson(user);
  const data = [
    { date: userflat.createdOn, title: "Application started" },
    { date: userflat.dataPrivacyConsentSignedOn, title: "Consent form signed" },
    ...Object.values(applicationConfigs).map((config) => {
      if (user[config.uploaded_key]) {
        return {
          title: config.title,
        };
      }
    }),
  ];

  return (
    <Timeline>
      {data.map((item, index) => {
        if (item)
          return (
            <Timeline.Item key={index}>
              <p style={{fontWeight:500}}>
                <>{item.title}</>
              </p>
              {item.date && (
                <p>
                  <>Date:</> {moment(item.date).format(timeFormat)}
                </p>
              )}
            </Timeline.Item>
          );
      })}
    </Timeline>
  );
};

export default TimelineLogs;
