import React from "react";
import { Tabs } from "antd";
import UserScriptInstall from "./UserScriptInstall";
import UserScriptUpdate from "./UserScriptUpdate";

const { TabPane } = Tabs;

export default function Instructions({ of }) {
  return (
    <Tabs defaultActiveKey={of}>
      <TabPane tab="Install" key="userscript_install">
        <UserScriptInstall />
      </TabPane>
      <TabPane tab="Update" key="userscript_update">
        <UserScriptUpdate />
      </TabPane>
    </Tabs>
  );
}
