import React from "react";
import "./App.css";
import AppRoutes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import AppConfig from "./AppConfig";
import { ConfigProvider } from "./contexts/configContext";
import Loading from "./components/Loading/Loading";
import { HelmetProvider } from "react-helmet-async";
export default function App() {
  return (
    <ConfigProvider>
      <HelmetProvider>
        <AppConfig>
          <Router>
            <AppRoutes />
          </Router>
        </AppConfig>
      </HelmetProvider>
    </ConfigProvider>
  );
}
