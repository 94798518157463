import { message } from "antd";

let msglist = {
  "Firebase: Error (auth/requires-recent-login).":
    "Please relogin as this is a sensitive operation.",
  "Cannot read properties of undefined (reading 'GHG Conversion Factor ')":
    "No matching factors found for this flight class and type.",
  "(storage/object-not-found)": "File not found!",
};

export function docsWithIds(snapshot) {
  return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export function docsObjects(snapshot) {
  let obj = {};
  snapshot.docs.forEach((doc) => {
    obj[doc.id] = doc.data();
  });
  return obj;
}
export function formatUsers(users) {
  return users.map((user) => {
    return {
      admin_of_companies: [],
      admin_of_sites: [],
      editor_of_sites: [],
      viewer_of_sites: [],
      ...user,
    };
  });
}

export async function except(
  func,
  loading_msg = "Loading",
  err_msg = "Error occured!",
  success_msg = "Successful",
  setLoading = null
) {
  setLoading && setLoading(true);
  let id = new Date().getTime();
  try {
    message.loading({ content: loading_msg, key: id }, 0);
    await func();
    message.destroy(id);
    message.success(success_msg);
  } catch (err) {
    let msg = err.response
      ? err.response.data
      : err.message
      ? err.message
      : err
      ? err
      : err_msg;
    if (msglist[msg]) message.error(msglist[msg]);
    else message.error(msg);
  } finally {
    message.destroy(id);
    setLoading && setLoading(false);
  }
}

export async function catcher(
  f,
  {
    loading_msg = "Loading",
    err_msg = "Error occured!",
    success_msg = "",
    setLoading = null,
  } = {}
) {
  let id = new Date().getTime();
  if (setLoading) {
    setLoading(true);
  } else {
    message.loading({ content: loading_msg, key: id }, 0);
  }

  try {
    await f();
    success_msg?.length && message.success(success_msg);
  } catch (err) {
    console.error(err);
    let msg = err.response?.data || err.message || err || err_msg;
    let msgDisplayed = false;

    if (msglist && typeof msglist === "object") {
      Object.keys(msglist).forEach((key) => {
        try{

          if ((key && key.includes(msg)) || (msg && msg.includes(key))) {
            message.error(msglist[key]);
            msgDisplayed = true;
          }
        }catch(err){
          
        }
      });
    }

    if (!msgDisplayed) {
      message.error(err_msg); // Use the fallback error message
    }
  } finally {
    message.destroy(id);
    if (setLoading) {
      setLoading(false);
    }
  }
}
