import React, { useState } from "react";
import { Checkbox, Typography, Select, Button, Card } from "antd";
import ScreenerForm from "../../components/Screener/Screener";
import "./style.css";
const { Link } = Typography;
const { Option } = Select;

const ConsentForm = ({ handleUpdate, elemOnly = false }) => {
  const [checked, setChecked] = useState(false);
  const [language, setLanguage] = useState("ENG");

  const consentText = {
    ENG: "I consent to the use and disclosure of my personal information in accordance with Link Health's privacy and data sharing policy.",
    SPA: "Doy mi consentimiento para el uso y la divulgación de mi información personal de acuerdo con la política de privacidad y compartición de datos de Link Health.",
    POR: "Consinto o uso e a divulgação das minhas informações pessoais de acordo com a política de privacidade e compartilhamento de dados da Link Health.",
  };

  const privacyPolicyLink = {
    ENG: "Read our Privacy Policy.",
    SPA: "Lee nuestra Política de Privacidad.",
    POR: "Leia nossa Política de Privacidade.",
  };

  const handleCheckboxChange = async (e) => {
    setChecked(e.target.checked);

    if (e.target.checked && elemOnly) {
      let userIpAddress = "Unavailable";
      try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        if (ipResponse.ok) {
          const ipData = await ipResponse.json();
          userIpAddress = ipData.ip;
        } else {
          console.error("Failed to fetch IP address:", ipResponse.statusText);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }

      handleUpdate({
        dataPrivacyConsent: true,
        dataPrivacyConsentSignedOn: new Date().getTime(),
        ipAddress: userIpAddress,
      });
    }
  };

  const handleSubmit = async () => {
    if (checked) {
      await handleUpdate({ dataPrivacyConsent: true, dataPrivacyConsentSignedOn: new Date().getTime() });
      console.log("Form submitted with consent:", { consent: true });
    } else {
      console.error("Please agree to the terms.");
    }
  };

  if (elemOnly) {
    return (
      <Checkbox checked={checked} onChange={handleCheckboxChange} style={{ fontSize: "16px" }}>
        {consentText[language]}{" "}
        <Link href="https://link-health.org/privacy-policy/" target="_blank">
          {privacyPolicyLink[language]}
        </Link>
      </Checkbox>
    );
  }

  return (
    <Card
      className="card-container"
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>Consent</h2>

          <Select defaultValue="ENG" onChange={(value) => setLanguage(value)} style={{ marginBottom: "10px" }}>
            <Option value="ENG">English</Option>
            <Option value="SPA">Español</Option>
            <Option value="POR">Português</Option>
          </Select>
        </div>
      }
      style={{
        maxWidth: "90%",
        padding: "20px",
      }}
      bodyStyle={{ padding: "20px" }}
    >
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <Checkbox checked={checked} onChange={handleCheckboxChange} style={{ fontSize: "16px" }}>
          {consentText[language]}
        </Checkbox>
        <br />
        <Link href="https://link-health.org/privacy-policy/" target="_blank" style={{ fontSize: "14px", color: "#1890ff" }}>
          {privacyPolicyLink[language]}
        </Link>
      </div>
      <Button type="primary" size="large" onClick={handleSubmit} disabled={!checked} style={{ width: "100%", fontSize: "16px" }}>
        {language === "ENG" ? "Submit" : language === "SPA" ? "Enviar" : "Enviar"}
      </Button>
    </Card>
  );
};
export default ConsentForm;