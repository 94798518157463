import { cleanObject, flattenJson } from "../../helpers/obj";
import { idXpath } from "../../helpers/xpath";
import { splitName } from "../Users/SNAP/generateSnapPdf/get";

export function mapDataToBabySteps(dataComplete) {
  const data = flattenJson(dataComplete);

  const config = {
    [idXpath("name-firstName")]: splitName(data?.name)?.first_name,
    [idXpath("name-lastName")]: splitName(data?.name)?.last_name,
    [idXpath("ssn-input")]: data?.ssn,
    [idXpath("phone-input")]: data?.phone,
    [idXpath("email-input")]: data?.email,
    [idXpath("address-addressLine1Value")]: data?.address,
    [idXpath("address-cityValue")]: data?.city,
    [idXpath("address-zipCodeValue")]: data?.zip,
    [idXpath("address-stateValue")]: "MA",
    [idXpath("select-select")]: "999",
  };

  return encodeURIComponent(JSON.stringify(cleanObject(config)));
}
