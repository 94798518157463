import React, { useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";
import Loading from "../../../../components/Loading/Loading";
import { fillPdfForm } from "./generateSNAPPDF";
import { fullDocConfig, systemgeneratedanswers } from "../config/document.config";
import { catcher } from "../../../../firebase/catcher";
import { Typography } from "antd";

function SNAPPdf({ user, openSnapForm }) {
  const [pdfSrc, setPdfSrc] = useState();
  console.log("SNAP",user);

  useEffect(() => {
    if (!user.snap_full_uploaded) return;
    catcher(async () => {
      const pdfBlob = await fillPdfForm(user);
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      setPdfSrc(pdfBlobUrl);
    });
  }, [user]);
  if (!user) return <Loading />;
  if (!user.snap_full_uploaded)
    return (
      <Typography.Title level={4}>
        To view the pdf, please fill the{" "}
        <a href="#" onClick={openSnapForm}>
          {" "}
          snap application
        </a>{" "}
        first.
      </Typography.Title>
    );
  if (!pdfSrc) return <Loading />;

  return <div>{pdfSrc && <iframe src={pdfSrc} style={{ width: "100%", height: "500px" }} frameBorder="0" title="Filled PDF"></iframe>}</div>;
}

export default SNAPPdf;
