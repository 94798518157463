export const fullUserObj = {
  name: null,
  address: null,
  email: null,
  phone: null,
  age: null,
  gender: null,
  number_in_household: null,
  rent_or_housing_cost: null,
  signature: null,
  dob: null,

  // "Language / Idioma": "English",
  // "Race / Raza": "Black or African American",
  // "Are you a U.S Citizen? / ¿Eres ciudadano americano?": "Yes",
  // "Social Security Number (If you are a U.S citizen) / Número de Seguro Social (Si eres ciudadano americano)": 25186497,
  // "Do you have Medicaid (MassHealth)? / ¿Tienes Medicaid (MassHealth)?": "Yes",
  // "Do you have any of these programs?": "None of the above",
  // "(SNAP Eligibility) Select one of the following / Selecciona una de las siguientes opciones":
  //   "You have a current bank balance (savings and checking combined) under $2,001",
  // "Are you homeless? / ¿No tienes hogar?": "No",
  // "Can you provide your signature? /¿Podrías darnos tu firma de autorización?":
  //   "https://drive.google.com/open?id=1zV1x8HAUSKzb9IFWPLou2Sqwh1bMm3jG",
  // "Do you have a Massachusetts Electronic Benefit Transfer (EBT) card? / ¿Tienes tarjeta EBT?":
  //   "No",
  // "Are you a person with a disability? /  ¿Tienes alguna discapacidad?": "No",
  // "If yes, please check off your impairment type(s) ": null,
  // "What is your preferred method of communication?": "In-Person",
  // "Has Massachusetts certified that you have a disability?": "No",
  // "Do other people live with you?": "Yes",
  // "Total number in household.": 1,
  // "List the members of your household.":
  //   "Bobby Kennedy, 12/2/2006, 0967833, Son, Y, N",
  // "Has anyone worked in the last 60 days?": "No",
  // "Income type": "Wages",
  // "Employer name or information": "MGB",
  // "Payment frequency": "Weekly",
  // "Gross Earnings for Last 4 Weeks": 1000,
  // "Income type.1": "Wages",
  // "Employer name or information.1": "Brigham",
  // "Payment frequency.1": "Weekly",
  // "Does anyone receive any other type of income such as Unemployment Compensation, Child Support, Social Security, SSI, Workers’ Compensation, Veterans’ Benefits, Pensions or Rental Income?":
  //   "No",
  // "Income Type": null,
  // "Benefit income frequency": "Bi-weekly",
  // "Gross amount": 2000,
  // "Is the household responsible to pay shelter costs?": "Yes",
  // "Type of shelter costs": "Rent",
  // "Shelter costs amount": 500,
  // "Payment frequency.2": "Monthly",
  // "Is the household responsible to pay utility costs separate from shelter costs?":
  //   "No",
  // "Utility costs detailed information": 200,
  // "Select the utility costs you pay":
  //   "Heat (oil, gas, electricity or propane, etc.)",
  // "Is anyone responsible to pay for adult or child care costs?": "No",
  // "Name of child/dependent": "Benny Blanco",
  // "Amount paid": 200,
  // "Frequency of payment": "Weekly",
  // "Does anyone who is at least 60 years old and/or disabled have out-of-pocket medical expenses?":
  //   "No",
  // "Does anyone who is at least 60 years old and/or disabled have out-of-pocket medical expenses?.1":
  //   "No",
  // "Name of the person": "Bob",
  // "Expense Type": "Medical care",
  // "Total cost per month": 1000,
  // "Does anyone travel to and/or from medical care (such as a pharmacy, doctor, therapist, etc.)?":
  //   "No",
  // "Name of the person.1": "Benjamin Ruxin",
  // "Address or medical provider": "12 Mohawk",
  // "Number of cars per week to the address indicated": 3,
  // "Number of cars per week from the address indicated": 2,
  // "Cost for Parking, Public Transportation, Taxi Cab, Shuttle,": 150,
  // "Is anyone in a training program at least 80 hours per month?": "No",
  // "If yes, provide the full name": "Hospital Cares",
  // "Is anyone working in exchange for goods and services (in-kind work)?": "No",
  // "If yes, provide the full name.": null,
  // "Is anyone doing an unpaid internship?": "No",
  // "If yes, provide the full name..1": null,
  // "Is anyone volunteering?": "Yes",
  // "Yes, provide the full name.": "GOTVax",
  // "Has anyone received SNAP benefits outside Massachusetts within the past 30 days?":
  //   "No",
  // "If yes, provide the full name..2": null,
  // "Do you want to give us permission to contact a person or agency if we cannot reach you by phone?":
  //   "Yes",
  // "If yes, provide the name of the person or the Agency.": "Link health",
  // "Provide a phone number": 8482180009,
  // "Provide a valid address": "12 mohawk st apartment 9",
  // "Do you want to give a person or agency permission to speak with DTA and get relevant confidential information about your case?":
  //   "Yes",
  // "If yes, provide the name of the person or Agency.": "Alister Martin",
  // "Provide a phone number.": 8482180009,
  // "Provide a valid address.": "12 mohawk st apartment 9",
  // "Do you want to give an agency or someone you trust permission to sign forms, report changes, complete interviews, and talk about your case with us?":
  //   "Yes",
  // "Provide the name of the person or Agency": null,
  // "Provide a valid contact number.": null,
  // "Provide a Federal Employer Identification Number (Agency Only):": null,
  // "Do you want to give someone you trust permission to get an EBT card to food shop for you using your SNAP benefits?":
  //   "Yes",
  // "If yes, provide the full name of the person": "Reese Markland",
  // "Provide a valid phone number": 7328297697,
  // "Provide a valid address": "217 Cotter",
  // "What is your heating source?": null,
  // "Provide the Company Name": null,
};
