import moment from "moment";
import { splitDate } from "../../helpers/datetime";
import { cleanObject, flattenJson } from "../../helpers/obj";
import { splitName } from "../Users/SNAP/generateSnapPdf/get";
import { timeFormat } from "../Users/SNAP/config/document.config";

export const mapDataToWic = (dataComplete) => {
  // used for auto filling
  const data = flattenJson(dataComplete);

  const config = {
    "//input[@type='text' and @autocomplete='given-name']": splitName(data?.name)?.first_name,
    "//input[@type='text' and @autocomplete='family-name']": splitName(data?.name)?.last_name,
    "//input[@placeholder='YYYY-MM-DD']": moment( data?.date_of_birth, timeFormat).format("YYYY-MM-DD"),
    "//input[@label='Email']": data?.email,
    "//input[@label='Confirm Email']": data?.email,
    "//input[contains(@class, 'fsFieldPhone')]": data?.phone,

    "//select[@label='Contact me by']": "Phone/Email",
    "//input[@label='Zip / Post Code']": data?.zip,
    "//input[@label='Address 1']": data?.address,

    "//select[@label='State']": "Massachusetts",
    "//input[@label='City']": data?.city,
    '//*[@name="field60605066" and @value="English"]':"English"
  };

  return encodeURIComponent(JSON.stringify(cleanObject(config)));
};
