import { auth } from "../config";

export default function isAdmin() {
  return ["admin@ahealthierdemocracy.org"].includes(auth.currentUser.email);
}
export function isFellow() {
  return ["fellow@ahealthierdemocracy.org"].includes(auth.currentUser.email);
}

export function isAmbassador() {
  return ["ambassador@ahealthierdemocracy.org"].includes(auth.currentUser.email);
}

export function isRemoteAmbassador() {
  return ["remoteambassador@ahealthierdemocracy.org"].includes(auth.currentUser.email);
}

export function isSandbox() {
  return ["sandbox@ahealthierdemocracy.org"].includes(auth.currentUser.email);
}
