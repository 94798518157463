import React, { useState } from "react";
import { useAtom } from "jotai";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { Table, Select, Divider } from "antd";
import moment from "moment";
import { allUsersAtom } from "../../atoms/users";
import Loading from "../../components/Loading/Loading";

const { Option } = Select;

const ScreeningResults = () => {
  const [users] = useAtom(allUsersAtom);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  if (!users) return <Loading />;

  // Helper function to check if a user is "screened" (has any `screener_` key)
  const isUserScreened = (user) => {
    return Object.keys(user).some((key) => key.startsWith("screener_"));
  };

  // Filter users for the current year and check if they are "screened"
  const currentYear = moment().year();
  const filteredUsers = users.filter((user) => moment(user.createdOn).year() === currentYear && isUserScreened(user));

  // Group users by month and day
  const groupedByDate = filteredUsers.reduce((acc, user) => {
    const month = moment(user.createdOn).format("MM");
    const day = moment(user.createdOn).format("DD");
    if (!acc[month]) acc[month] = {};
    if (!acc[month][day]) acc[month][day] = [];
    acc[month][day].push(user);
    return acc;
  }, {});

  // Sort months in correct order (January to December)
  const availableMonths = Object.keys(groupedByDate).sort((a, b) => a - b);

  // Generate columns dynamically for screener_result_* and screener_state_*
  const generateColumns = (users) => {
    const sampleUser = users[0] || {};
    const resultColumns = [];
    const stateColumns = [];

    Object.keys(sampleUser).forEach((key) => {
      if (key.startsWith("screener_result_")) {
        resultColumns.push({
          title: `Result: ${key.replace("screener_result_", "").toUpperCase()}`,
          dataIndex: [key, "value"],
          key,
          render: (value) => (value ? "Yes" : "No"),
        });
      } else if (key.startsWith("screener_state_")) {
        stateColumns.push({
          title: `State: ${key.replace("screener_state_", "").toUpperCase()}`,
          dataIndex: [key, "value"],
          key,
          render: (value) => (value ? "Yes" : "No"),
        });
      }
    });

    // Add `id` and `name` columns at the beginning
    return [
      { title: "ID", dataIndex: "id", key: "id" },
      // { title: "Name", render: (user) => {
      //   if(typeof user==="object"){
          
      //   }
      // }, key: "name" },
      ...stateColumns, // States on the left
      ...resultColumns, // Results on the right
    ];
  };

  // Prepare chart data for the selected month (only for screened users)
  const getChartData = () => {
    if (!selectedMonth) return [];
    const monthData = groupedByDate[selectedMonth];
    // Sort by day (1, 2, 3...)
    const sortedDays = Object.keys(monthData).sort((a, b) => a - b);
    return sortedDays.map((day) => ({
      day,
      totalScreened: monthData[day].length,
    }));
  };

  // Handle month selection
  const handleMonthChange = (value) => {
    setSelectedMonth(value);
    setSelectedDate(null); // Reset selected day when changing month
  };

  // Handle bar click to show users for the selected day
  const handleBarClick = (data) => {
    if (data && data.day) {
      setSelectedDate(data.day);
    }
  };

  return (
    <div>
      {/* Month Filter */}
      <div style={{ marginBottom: "20px" }}>
        <Select placeholder="Select a month" style={{ width: 200 }} onChange={handleMonthChange} value={selectedMonth}>
          {availableMonths.map((month) => (
            <Option key={month} value={month}>
              {moment(month, "MM").format("MMMM")}
            </Option>
          ))}
        </Select>
      </div>

      {/* Bar Chart for Selected Month */}
      {selectedMonth && (
        <div style={{ marginBottom: "40px" }}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={getChartData()} onClick={(e) => handleBarClick(e.activePayload ? e.activePayload[0]?.payload : null)}>
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="totalScreened" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {/* Display users table if a date is selected */}
      {selectedDate && (
        <>
          <Divider />
          <h2>Screening Results for Day {selectedDate}</h2>
          <Table
            dataSource={groupedByDate[selectedMonth][selectedDate]}
            columns={generateColumns(groupedByDate[selectedMonth][selectedDate])}
            rowKey={(record) => record.id || record.email} // Assuming each user has a unique id or email
            pagination={{ pageSize: 5 }} // Ensure pagination works properly
            scroll={{ x: "max-content" }} // Enable horizontal scroll for table if too many columns
            sticky
          />
        </>
      )}
    </div>
  );
};

export default ScreeningResults;
