import React, { useRef, useState } from "react";
import { Modal, Button, Tabs, Upload, Typography, Alert } from "antd";
import { UploadOutlined, CameraOutlined } from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import Webcam from "react-webcam";
import { FaPen, FaSignature } from "react-icons/fa6";
import useSize from "../../../components/use/useSize";

const { TabPane } = Tabs;

const WIDTH = 1000;
const HEIGHT = 270;
const videoConstraints = {
  width: WIDTH,
  height: HEIGHT,
  facingMode: "user",
};

export default function UserSignature({ open, setOpen, saveSignature }) {
  const sigCanvasRef = useRef(null);
  const webcamRef = useRef(null);
  const [activeTab, setActiveTab] = useState("draw");

  const { isMobile } = useSize();

  const clear = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }
  };

  const save = () => {
    if (activeTab === "draw" && sigCanvasRef.current) {
      const signatureImage = sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
      saveSignature(signatureImage);
    } else if (activeTab === "camera") {
      const imageSrc = webcamRef.current.getScreenshot();
      saveSignature(imageSrc);
    }
    setOpen(false);
  };

  // Handle file upload
  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      saveSignature(e.target.result);
    };
    reader.readAsDataURL(file);
    return false; // Prevent upload
  };

  const uploadProps = {
    beforeUpload: beforeUpload,
    showUploadList: false,
  };

  return (
    <Modal
      title={
        <>
          Add Signature <FaPen />
        </>
      }
      open={open}
      width={WIDTH+40}
      onCancel={() => setOpen(false)}
      onOk={save}
      okText="Save"
    >
      <Typography.Title level={4}>
        {/* <Alert
          type="warning"
          showIcon
          message={`
          Saving the signature completes the application and triggers essential emails/faxes. Please ensure this step is completed last.`}
        ></Alert> */}
      </Typography.Title>
      <Tabs defaultActiveKey="draw" onChange={setActiveTab}>
        <TabPane tab="Draw Signature" key="draw">
          <div style={{ border: "1px dotted darkgrey" }} className="pen">
            <SignatureCanvas
            
              ref={sigCanvasRef}
              dotSize={12}
              penColor="black"
              canvasProps={{
                width: isMobile ?400: WIDTH,
                height: HEIGHT+200,
                // className: "sigCanvas",
              }}
            />
          </div>
          <Button onClick={clear} style={{ marginTop: 16 }}>
            Clear
          </Button>
        </TabPane>
        <TabPane tab="Upload Signature" key="upload">
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </TabPane>
        <TabPane tab="Take a Picture" key="camera">
          <Webcam audio={false} height={HEIGHT} ref={webcamRef} screenshotFormat="image/png" width={WIDTH} videoConstraints={videoConstraints} />
          <Button type="primary" icon={<CameraOutlined />} onClick={save} style={{ marginTop: 16 }}>
            Capture
          </Button>
        </TabPane>
      </Tabs>
    </Modal>
  );
}
