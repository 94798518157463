// src/UpdateScriptPage.js
import React, { useEffect, useState } from "react";
import { Typography, Button, Alert, Card, Space } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const { Title, Paragraph, Link } = Typography;

const UpdateScriptInstructions = () => {
  const [scriptCode, setScriptCode] = useState("");

  useEffect(() => {
    fetch("/userscript.txt")
      .then((response) => response.text())
      .then((data) => setScriptCode(data))
      .catch((error) => console.error("Error fetching the text file:", error));
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(scriptCode)
      .then(() => {
        alert("Script code copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <div style={{ padding: 24 }}>
      <Title>Update Tampermonkey User Script</Title>
      {/* Option 1: Automatically Update User Script */}
      <Card>
        <Title level={4}>
          (preffered option)<br></br> Automatically Update User Script
        </Title>
        <Paragraph>
          Go over this link{" "}
          <a
            href="https://greasyfork.org/en/scripts/495168-link-health-user-script"
            target="_blank"
          >
            https://greasyfork.org/en/scripts/495168-link-health-user-script
          </a>{" "}
          and update the script. Reload this webpage afterwards and the script
          should be working.
        </Paragraph>
      </Card>
      {/* Option 2: Manually Update User Script */}
      <Card>
        <Title level={4}>
          (if the above didnt work)
          <br /> Manually Update User Script
        </Title>
        <Paragraph>
          If the automatic update does not work, you can manually update the
          script using the steps below:
        </Paragraph>
        <Paragraph>
          <strong>Step 1:</strong> Open the Tampermonkey dashboard.
        </Paragraph>
        <Paragraph>
          <strong>Step 2:</strong> Find the script you want to update and click
          the edit (pencil) icon next to it.
        </Paragraph>
        <Paragraph>
          <strong>Step 3:</strong> Delete the existing script code and replace
          it with the new script code provided below.
        </Paragraph>
        <Space>
          <Button
            type="primary"
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
          >
            Copy Updated Script Code
          </Button>
        </Space>
        <Paragraph>
          <strong>Step 4:</strong> Click the "File" menu, then select "Save".
          Your script should now be updated.
        </Paragraph>
        <Paragraph>
          <a
            href="https://drive.google.com/file/d/1ekSeAJcq4aG1jx0KNi60P1PujVw3o7S1/view?usp=sharing"
            target="_blank"
          >
            View video instuctions
          </a>
        </Paragraph>
      </Card>
    </div>
  );
};

export default UpdateScriptInstructions;
