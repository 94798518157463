import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getApp } from "firebase/app";

export const saveUnfilledData = (uniqueId, formData) => {
  try{

    const db = getFirestore(getApp());
    const unfilledData = {
    ...formData,
    id: uniqueId,
    timestamp: new Date().toISOString(),
  };

  try {
    const docRef = doc(db, "Unfilled", uniqueId);
    setDoc(docRef, {str:JSON.stringify(unfilledData)})
      .then(() => {
        console.log("Document successfully written with ID: ", uniqueId);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  } catch (error) {
    console.error("Error saving data: ", error);
    console.error("error saving unfilled data", uniqueId, formData)
  }
  }catch(err){
    console.error("error saving unfilled data", uniqueId, formData)
  }
};
