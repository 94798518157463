"use client";
import React, { useState, useEffect, useCallback } from "react";
import { Form, Input, Select, Radio, Checkbox, Button } from "antd";
import { debounce } from "lodash";

const { Option } = Select;

const FormComponent = ({ formData }) => {
  const [form] = Form.useForm();
  const initialData = {};
  const [formValues, setFormValues] = useState(initialData);

  // Debounced function to update form values in state
  const debouncedUpdateFormValues = useCallback(
    debounce((values) => {
      setFormValues(values);
    }, 300),
    []
  );

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [form, formValues]);

  useEffect(() => {
    // localStorage.setItem("formData", JSON.stringify(formValues));
  }, [formValues]);

  const handleFormChange = (_, allValues) => {
    // Update the form values directly
    debouncedUpdateFormValues(allValues);
  };

  const renderField = (field) => {
    const { title, id, required, type, options, info } = field;

    switch (type) {
      case "text":
        return (
          <Form.Item
            key={id}
            label={title}
            name={id}
            rules={[{ required, message: `Please enter ${title.toLowerCase()}` }]}
          >
            <Input placeholder={info || title} />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item
            key={id}
            label={title}
            name={id}
            rules={[{ required, message: `Please select ${title.toLowerCase()}` }]}
          >
            <Select placeholder="Select an option" showSearch>
              {options?.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item
            key={id}
            label={title}
            name={id}
            rules={[{ required, message: `Please select ${title.toLowerCase()}` }]}
          >
            <Radio.Group>
              {options?.map((option) => (
                <Radio.Button key={option.value} value={option.value}>
                  {option.text}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item
            key={id}
            label={title}
            name={id}
            valuePropName="checked"
          >
            <Checkbox.Group style={{ display: "flex", flexDirection: "column" }}>
              {options?.map((option) => (
                <Checkbox key={option.value} value={option.value}>
                  {option.text}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      <Form
        form={form}
        size="large"
        layout="vertical"
        onValuesChange={handleFormChange}
        initialValues={formValues}
        requiredMark={false}
        className="form"
      >
        {formData.map((field) => renderField(field))}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};



const formData = [
  {
    title: "What language do you prefer?",
    id: "QID6",
    required: true,
    type: "radio",
    options: [
      {
        value: "1",
        text: "English",
      },
      {
        value: "4",
        text: "Spanish",
      },
    ],
  },
  {
    title: "Do you prefer a morning or afternoon appointment?",
    id: "QID15",
    required: true,
    type: "radio",
    options: [
      {
        value: "1",
        text: "Morning",
      },
      {
        value: "2",
        text: "Afternoon",
      },
    ],
  },
  {
    title: "First Name",
    id: "QR~QID11~1",
    required: true,
    type: "text",
    info: "Please enter your first name.",
  },
  {
    title: "Last Name",
    id: "QR~QID11~6",
    required: true,
    type: "text",
    info: "Please enter your last name.",
  },
  {
    title: "Address",
    id: "QR~QID11~2",
    required: false,
    type: "text",
    info: "Please enter your address including apartment number if applicable.",
  },
  {
    title: "City",
    id: "QR~QID11~3",
    required: true,
    type: "text",
    info: "Please enter your city.",
  },
  {
    title: "Date of Birth",
    id: "QR~QID11~4",
    required: true,
    type: "text",
    info: "Please enter your date of birth in MM/DD/YYYY format.",
  },
  {
    title: "Email",
    id: "QR~QID11~5",
    required: true,
    type: "text",
    info: "Please enter your email address.",
  },
  {
    title: "How many people live in your household?",
    id: "QID44",
    required: true,
    type: "text",
    info: "Enter a number between 1 and 16.",
  },
  {
    title: "Select COUNTY from the dropdown list below",
    id: "QID1",
    required: true,
    type: "select",
    options: [
      {
        value: "1",
        text: "ANDERSON",
      },
      {
        value: "2",
        text: "ANDREWS",
      },
      {
        value: "3",
        text: "ANGELINA",
      },
      {
        value: "4",
        text: "ARANSAS",
      },
      {
        value: "5",
        text: "ARCHER",
      },
      {
        value: "6",
        text: "ARMSTRONG",
      },
      {
        value: "7",
        text: "ATASCOSA",
      },
      {
        value: "8",
        text: "AUSTIN",
      },
      {
        value: "9",
        text: "BAILEY",
      },
      {
        value: "10",
        text: "BANDERA",
      },
      {
        value: "11",
        text: "BASTROP",
      },
      {
        value: "12",
        text: "BAYLOR",
      },
      {
        value: "13",
        text: "BEE",
      },
      {
        value: "14",
        text: "BELL",
      },
      {
        value: "15",
        text: "BEXAR",
      },
      {
        value: "16",
        text: "BLANCO",
      },
      {
        value: "17",
        text: "BORDEN",
      },
      {
        value: "18",
        text: "BOSQUE",
      },
      {
        value: "19",
        text: "BOWIE",
      },
      {
        value: "20",
        text: "BRAZORIA",
      },
      {
        value: "21",
        text: "BRAZOS",
      },
      {
        value: "22",
        text: "BREWSTER",
      },
      {
        value: "23",
        text: "BRISCOE",
      },
      {
        value: "24",
        text: "BROOKS",
      },
      {
        value: "25",
        text: "BROWN",
      },
      {
        value: "26",
        text: "BURLESON",
      },
      {
        value: "27",
        text: "BURNET",
      },
      {
        value: "28",
        text: "CALDWELL",
      },
      {
        value: "29",
        text: "CALHOUN",
      },
      {
        value: "30",
        text: "CALLAHAN",
      },
      {
        value: "31",
        text: "CAMERON",
      },
      {
        value: "32",
        text: "CAMP",
      },
      {
        value: "33",
        text: "CARSON",
      },
      {
        value: "34",
        text: "CASS",
      },
      {
        value: "35",
        text: "CASTRO",
      },
      {
        value: "36",
        text: "CHAMBERS",
      },
      {
        value: "37",
        text: "CHEROKEE",
      },
      {
        value: "38",
        text: "CHILDRESS",
      },
      {
        value: "39",
        text: "CLAY",
      },
      {
        value: "40",
        text: "COCHRAN",
      },
      {
        value: "41",
        text: "COKE",
      },
      {
        value: "42",
        text: "COLEMAN",
      },
      {
        value: "43",
        text: "COLLIN",
      },
      {
        value: "44",
        text: "COLLINGSWORTH",
      },
      {
        value: "45",
        text: "COLORADO",
      },
      {
        value: "46",
        text: "COMAL",
      },
      {
        value: "47",
        text: "COMANCHE",
      },
      {
        value: "48",
        text: "CONCHO",
      },
      {
        value: "49",
        text: "COOKE",
      },
      {
        value: "50",
        text: "CORYELL",
      },
      {
        value: "51",
        text: "COTTLE",
      },
      {
        value: "52",
        text: "CRANE",
      },
      {
        value: "53",
        text: "CROCKETT",
      },
      {
        value: "54",
        text: "CROSBY",
      },
      {
        value: "55",
        text: "CULBERSON",
      },
      {
        value: "56",
        text: "DALLAM",
      },
      {
        value: "57",
        text: "DALLAS",
      },
      {
        value: "58",
        text: "DAWSON",
      },
      {
        value: "59",
        text: "DEAF SMITH",
      },
      {
        value: "60",
        text: "DELTA",
      },
      {
        value: "61",
        text: "DENTON",
      },
      {
        value: "62",
        text: "DEWITT",
      },
      {
        value: "63",
        text: "DICKENS",
      },
      {
        value: "64",
        text: "DIMMIT",
      },
      {
        value: "65",
        text: "DONLEY",
      },
      {
        value: "66",
        text: "DUVAL",
      },
      {
        value: "67",
        text: "EASTLAND",
      },
      {
        value: "68",
        text: "ECTOR",
      },
      {
        value: "69",
        text: "EDWARDS",
      },
      {
        value: "70",
        text: "ELLIS",
      },
      {
        value: "71",
        text: "EL PASO",
      },
      {
        value: "72",
        text: "ERATH",
      },
      {
        value: "73",
        text: "FALLS",
      },
      {
        value: "74",
        text: "FANNIN",
      },
      {
        value: "75",
        text: "FAYETTE",
      },
      {
        value: "76",
        text: "FISHER",
      },
      {
        value: "77",
        text: "FLOYD",
      },
      {
        value: "78",
        text: "FOARD",
      },
      {
        value: "79",
        text: "FORT BEND",
      },
      {
        value: "80",
        text: "FRANKLIN",
      },
      {
        value: "81",
        text: "FREESTONE",
      },
      {
        value: "82",
        text: "FRIO",
      },
      {
        value: "83",
        text: "GAINES",
      },
      {
        value: "84",
        text: "GALVESTON",
      },
      {
        value: "85",
        text: "GARZA",
      },
      {
        value: "86",
        text: "GILLESPIE",
      },
      {
        value: "87",
        text: "GLASSCOCK",
      },
      {
        value: "88",
        text: "GOLIAD",
      },
      {
        value: "89",
        text: "GONZALES",
      },
      {
        value: "90",
        text: "GRAY",
      },
      {
        value: "91",
        text: "GRAYSON",
      },
      {
        value: "92",
        text: "GREGG",
      },
      {
        value: "93",
        text: "GRIMES",
      },
      {
        value: "94",
        text: "GUADALUPE",
      },
      {
        value: "95",
        text: "HALE",
      },
      {
        value: "96",
        text: "HALL",
      },
      {
        value: "97",
        text: "HAMILTON",
      },
      {
        value: "98",
        text: "HANSFORD",
      },
      {
        value: "99",
        text: "HARDEMAN",
      },
      {
        value: "100",
        text: "HARDIN",
      },
      {
        value: "101",
        text: "HARRIS",
      },
      {
        value: "102",
        text: "HARRISON",
      },
      {
        value: "103",
        text: "HARTLEY",
      },
      {
        value: "104",
        text: "HASKELL",
      },
      {
        value: "105",
        text: "HAYS",
      },
      {
        value: "106",
        text: "HEMPHILL",
      },
      {
        value: "107",
        text: "HENDERSON",
      },
      {
        value: "108",
        text: "HIDALGO",
      },
      {
        value: "109",
        text: "HILL",
      },
      {
        value: "110",
        text: "HOCKLEY",
      },
      {
        value: "111",
        text: "HOOD",
      },
      {
        value: "112",
        text: "HOPKINS",
      },
      {
        value: "113",
        text: "HOUSTON",
      },
      {
        value: "114",
        text: "HOWARD",
      },
      {
        value: "115",
        text: "HUDSPETH",
      },
      {
        value: "116",
        text: "HUNT",
      },
      {
        value: "117",
        text: "HUTCHINSON",
      },
      {
        value: "118",
        text: "IRION",
      },
      {
        value: "119",
        text: "JACK",
      },
      {
        value: "120",
        text: "JACKSON",
      },
      {
        value: "121",
        text: "JASPER",
      },
      {
        value: "122",
        text: "JEFF DAVIS",
      },
      {
        value: "123",
        text: "JEFFERSON",
      },
      {
        value: "124",
        text: "JIM HOGG",
      },
      {
        value: "125",
        text: "JIM WELLS",
      },
      {
        value: "126",
        text: "JOHNSON",
      },
      {
        value: "127",
        text: "JONES",
      },
      {
        value: "128",
        text: "KARNES",
      },
      {
        value: "129",
        text: "KAUFMAN",
      },
      {
        value: "130",
        text: "KENDALL",
      },
      {
        value: "131",
        text: "KENEDY",
      },
      {
        value: "132",
        text: "KENT",
      },
      {
        value: "133",
        text: "KERR",
      },
      {
        value: "134",
        text: "KIMBLE",
      },
      {
        value: "135",
        text: "KING",
      },
      {
        value: "136",
        text: "KINNEY",
      },
      {
        value: "137",
        text: "KLEBERG",
      },
      {
        value: "138",
        text: "KNOX",
      },
      {
        value: "139",
        text: "LAMAR",
      },
      {
        value: "140",
        text: "LAMB",
      },
      {
        value: "141",
        text: "LAMPASAS",
      },
      {
        value: "142",
        text: "LA SALLE",
      },
      {
        value: "143",
        text: "LAVACA",
      },
      {
        value: "144",
        text: "LEE",
      },
      {
        value: "145",
        text: "LEON",
      },
      {
        value: "146",
        text: "LIBERTY",
      },
      {
        value: "147",
        text: "LIMESTONE",
      },
      {
        value: "148",
        text: "LIPSCOMB",
      },
      {
        value: "149",
        text: "LIVE OAK",
      },
      {
        value: "150",
        text: "LLANO",
      },
      {
        value: "151",
        text: "LOVING",
      },
      {
        value: "152",
        text: "LUBBOCK",
      },
      {
        value: "153",
        text: "LYNN",
      },
      {
        value: "154",
        text: "MCCULLOCH",
      },
      {
        value: "155",
        text: "MCLENNAN",
      },
      {
        value: "156",
        text: "MCMULLEN",
      },
      {
        value: "157",
        text: "MADISON",
      },
      {
        value: "158",
        text: "MARION",
      },
      {
        value: "159",
        text: "MARTIN",
      },
      {
        value: "160",
        text: "MASON",
      },
      {
        value: "161",
        text: "MATAGORDA",
      },
      {
        value: "162",
        text: "MAVERICK",
      },
      {
        value: "163",
        text: "MEDINA",
      },
      {
        value: "164",
        text: "MENARD",
      },
      {
        value: "165",
        text: "MIDLAND",
      },
      {
        value: "166",
        text: "MILAM",
      },
      {
        value: "167",
        text: "MILLS",
      },
      {
        value: "168",
        text: "MITCHELL",
      },
      {
        value: "169",
        text: "MONTAGUE",
      },
      {
        value: "170",
        text: "MONTGOMERY",
      },
      {
        value: "171",
        text: "MOORE",
      },
      {
        value: "172",
        text: "MORRIS",
      },
      {
        value: "173",
        text: "MOTLEY",
      },
      {
        value: "174",
        text: "NACOGDOCHES",
      },
      {
        value: "175",
        text: "NAVARRO",
      },
      {
        value: "176",
        text: "NEWTON",
      },
      {
        value: "177",
        text: "NOLAN",
      },
      {
        value: "178",
        text: "NUECES",
      },
      {
        value: "179",
        text: "OCHILTREE",
      },
      {
        value: "180",
        text: "OLDHAM",
      },
      {
        value: "181",
        text: "ORANGE",
      },
      {
        value: "182",
        text: "PALO PINTO",
      },
      {
        value: "183",
        text: "PANOLA",
      },
      {
        value: "184",
        text: "PARKER",
      },
      {
        value: "185",
        text: "PARMER",
      },
      {
        value: "186",
        text: "PECOS",
      },
      {
        value: "187",
        text: "POLK",
      },
      {
        value: "188",
        text: "POTTER",
      },
      {
        value: "189",
        text: "PRESIDIO",
      },
      {
        value: "190",
        text: "RAINS",
      },
      {
        value: "191",
        text: "RANDALL",
      },
      {
        value: "192",
        text: "REAGAN",
      },
      {
        value: "193",
        text: "REAL",
      },
      {
        value: "194",
        text: "RED RIVER",
      },
      {
        value: "195",
        text: "REEVES",
      },
      {
        value: "196",
        text: "REFUGIO",
      },
      {
        value: "197",
        text: "ROBERTS",
      },
      {
        value: "198",
        text: "ROBERTSON",
      },
      {
        value: "199",
        text: "ROCKWALL",
      },
      {
        value: "200",
        text: "RUNNELS",
      },
      {
        value: "201",
        text: "RUSK",
      },
      {
        value: "202",
        text: "SABINE",
      },
      {
        value: "203",
        text: "SAN AUGUSTINE",
      },
      {
        value: "204",
        text: "SAN JACINTO",
      },
      {
        value: "205",
        text: "SAN PATRICIO",
      },
      {
        value: "206",
        text: "SAN SABA",
      },
      {
        value: "207",
        text: "SCHLEICHER",
      },
      {
        value: "208",
        text: "SCURRY",
      },
      {
        value: "209",
        text: "SHACKELFORD",
      },
      {
        value: "210",
        text: "SHELBY",
      },
      {
        value: "211",
        text: "SHERMAN",
      },
      {
        value: "212",
        text: "SMITH",
      },
      {
        value: "213",
        text: "SOMERVELL",
      },
      {
        value: "214",
        text: "STARR",
      },
      {
        value: "215",
        text: "STEPHENS",
      },
      {
        value: "216",
        text: "STERLING",
      },
      {
        value: "217",
        text: "STONEWALL",
      },
      {
        value: "218",
        text: "SUTTON",
      },
      {
        value: "219",
        text: "SWISHER",
      },
      {
        value: "220",
        text: "TARRANT",
      },
      {
        value: "221",
        text: "TAYLOR",
      },
      {
        value: "222",
        text: "TERRELL",
      },
      {
        value: "223",
        text: "TERRY",
      },
      {
        value: "224",
        text: "THROCKMORTON",
      },
      {
        value: "225",
        text: "TITUS",
      },
      {
        value: "226",
        text: "TOM GREEN",
      },
      {
        value: "227",
        text: "TRAVIS",
      },
      {
        value: "228",
        text: "TRINITY",
      },
      {
        value: "229",
        text: "TYLER",
      },
      {
        value: "230",
        text: "UPSHUR",
      },
      {
        value: "231",
        text: "UPTON",
      },
      {
        value: "232",
        text: "UVALDE",
      },
      {
        value: "233",
        text: "VAL VERDE",
      },
      {
        value: "234",
        text: "VAN ZANDT",
      },
      {
        value: "235",
        text: "VICTORIA",
      },
      {
        value: "236",
        text: "WALKER",
      },
      {
        value: "237",
        text: "WALLER",
      },
      {
        value: "238",
        text: "WARD",
      },
      {
        value: "239",
        text: "WASHINGTON",
      },
      {
        value: "240",
        text: "WEBB",
      },
      {
        value: "241",
        text: "WHARTON",
      },
      {
        value: "242",
        text: "WHEELER",
      },
      {
        value: "243",
        text: "WICHITA",
      },
      {
        value: "244",
        text: "WILBARGER",
      },
      {
        value: "245",
        text: "WILLACY",
      },
      {
        value: "246",
        text: "WILLIAMSON",
      },
      {
        value: "247",
        text: "WILSON",
      },
      {
        value: "248",
        text: "WINKLER",
      },
      {
        value: "249",
        text: "WISE",
      },
      {
        value: "250",
        text: "WOOD",
      },
      {
        value: "251",
        text: "YOAKUM",
      },
      {
        value: "252",
        text: "YOUNG",
      },
      {
        value: "253",
        text: "ZAPATA",
      },
      {
        value: "254",
        text: "ZAVALA",
      },
    ],
  },
  {
    title: "Zip Code",
    id: "QID2",
    required: true,
    type: "text",
    info: "Please enter your zip code.",
  },
  {
    title: "Phone Number",
    id: "QID5",
    required: true,
    type: "text",
    info: "Please enter your phone number.",
  },
  {
    title: "Is it ok to send you a text message or an email?",
    id: "QID10",
    required: true,
    type: "radio",
    options: [
      {
        value: "1",
        text: "Yes",
      },
      {
        value: "2",
        text: "No",
      },
    ],
  },
  {
    title: "Have you been on WIC before?",
    id: "QID14",
    required: true,
    type: "radio",
    options: [
      {
        value: "1",
        text: "Yes",
      },
      {
        value: "2",
        text: "No",
      },
    ],
  },
  {
    title: "WIC benefits are for pregnant and postpartum mothers and children up to the age of 5 years. Please select the statement(s) below that are true for you.",
    id: "QID7",
    required: false,
    type: "checkbox",
    options: [
      {
        value: "2",
        text: "I have an infant less than 1 year old.",
      },
      {
        value: "3",
        text: "I have a child between 1 and 5 years old.",
      },
      {
        value: "1",
        text: "I am pregnant.",
      },
      {
        value: "4",
        text: "I am a foster parent or legal guardian of a child less than 5 years old.",
      },
      {
        value: "6",
        text: "I experienced a pregnancy loss that was less than 6 months ago.",
      },
      {
        value: "5",
        text: "None of the above",
      },
    ],
  },
  {
    title: "How did you hear about WIC?",
    id: "QID8",
    required: false,
    type: "checkbox",
    options: [
      {
        value: "9",
        text: "SNAP or Food Stamps",
      },
      {
        value: "1",
        text: "Friend or family",
      },
      {
        value: "2",
        text: "Website/Internet search",
      },
      {
        value: "3",
        text: "Facebook, Snapchat, Instagram",
      },
      {
        value: "4",
        text: "Doctor's office or hospital",
      },
      {
        value: "11",
        text: "Medicaid",
      },
      {
        value: "12",
        text: "TANF",
      },
      {
        value: "5",
        text: "Lactation support center",
      },
      {
        value: "6",
        text: "Community event",
      },
      {
        value: "7",
        text: "Radio or television",
      },
      {
        value: "8",
        text: "Other",
      },
      {
        value: "10",
        text: "1-800-WIC-FORU (1-800-942-3678)",
      },
    ],
  },
  {
    title: "Additional information, if applicable",
    id: "QID22",
    required: false,
    type: "text",
    info: "Provide any additional information that might be relevant.",
  },
  {
    title: "If you are a current Texas WIC client, enter your 19 digit WIC card number here.",
    id: "QID31",
    required: false,
    type: "text",
    info: "Enter your 19 digit WIC card number if you have one.",
  },
];

export default function Main() {
  return (
    <div style={{ maxWidth: 800, margin: "0 auto", padding: "2rem" }}>
      <h1>Texas Wic Form</h1>
      <p style={{ color: "darkblue" }}>Submit this form to get wic benefits in texas.</p>
      <FormComponent formData={formData} />
    </div>
  );
}
