function capitalizeWords(str) {
  if (typeof str !== "string") return str;
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function beautifyKey(key) {
  if (window.location.href.includes("localhost")) return key;
  return capitalizeWords(
    String(key.replace(/([A-Z])/g, " $1").trim())
      .replaceAll("_", " ")
      .replaceAll("-", " ")
  );
}

export function formatObjectIntoStr(obj) {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (value && value !== "") {
        return `${capitalizeWords(key)}: ${capitalizeWords(value)}\n`;
      }
    })
    .join("\n");
}
