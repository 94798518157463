import React from 'react';
import { Result } from 'antd';

const ThankYouMessage = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
      <Result
        status="success"
        title="Thank You!"
        subTitle="Thank you for submitting the application. We have received your information and will process it shortly."
      />
    </div>
  );
};

export default ThankYouMessage;
