import React, { useEffect, useState } from "react";
import FormQuestions from "../../../Users/SNAP/Form";
import { PiClockDuotone } from "react-icons/pi";
import { Button, Divider, Image, Space } from "antd";
import { BsArrowRight } from "react-icons/bs";
import UserSignature from "../../../Users/modals/UserSignature";
import { TiPencil, TiTickOutline } from "react-icons/ti";
import { createUser } from "../../../../firebase/users/set";
import { catcher } from "../../../../firebase/catcher";
import { MdModeEditOutline } from "react-icons/md";
import Loading from "../../../../components/Loading/Loading";
import ThankYouMessage from "../../../../components/UI/ThankyouForSubmitting";
import { Helmet } from "react-helmet-async";
import ConsentForm from "../../../consents/DataPrivacyConsent";

const MassSNAP = () => {
  const [data, setData] = useState({ signature: false });
  const [loading, setLoading] = React.useState(false);
  const [modalSignature, setModalSignature] = React.useState(false);
  const [formSaved, setSaved] = React.useState(false);
  const save = async (newDataArr) => {
    const newData = {};
    newDataArr.forEach((item) => {
      newData[item.id] = item;
    });
    if (!data.dataPrivacyConsent) {
      // setModalSignature(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can also use 'auto' for an instant scroll
      });
      return alert("Please provide your consent");
    }
    if (!data.signature) {
      setModalSignature(true);
      return alert("Please add signature");
    }
    await catcher(
      async () => {
        console.log("DATA--", data);
        const dataToSave = {
          ...data,
          ...newData,
          loc: "ma",
          name: { id: "name", value: newData.first_name.value + " " + newData.last_name.value },
          input_by_user: true,
          snap_full_uploaded: true,
          createdOn: new Date().getTime(),
          application_incomplete: false,
          unfilled_id: localStorage.getItem("unfilledId") || "none",
        };
        console.log("final data to be saved", dataToSave);
        await createUser(dataToSave);
        setSaved(true);
      },
      { setLoading }
    );
  };
  const saveSignature = (signature) => {
    setData({ ...data, signature: signature });
  };

  // components
  const takesTimeSection = (
    <h4>
      <PiClockDuotone /> Takes 5-10 min to complete.
    </h4>
  );
  const renderFormBody = () => {
    if (formSaved) {
      return <ThankYouMessage />;
    } else
      return (
        <>
          {loading && <Loading />}
          <div style={{ display: loading ? "none" : "defaultf" }}>
            {takesTimeSection}
            <Divider />
            <FormQuestions
              type={"fullsnap"}
              user={{}}
              is_public={true}
              extras={
                <div>
                  <div id="snap_signature">
                    {data.signature ? (
                      <>
                        <Button size="large" onClick={() => setModalSignature(true)} type="link" style={{ color: "green" }}>
                          Edit Signature <MdModeEditOutline />
                        </Button>
                        <br />
                        <img
                          style={{ width: "200px", height: "100px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setModalSignature(true);
                          }}
                          src={data.signature}
                        />
                      </>
                    ) : (
                      <Button type="primary" onClick={() => setModalSignature(true)}>
                        Add Signature <TiPencil />
                      </Button>
                    )}
                  </div>
                  <div id="consent_box" style={{marginBottom:"30px"}}>
                    <h3 className="label">Consent</h3>
                    <ConsentForm elemOnly handleUpdate={(consentData) => setData({ ...data, ...consentData })} />
                  </div>
                </div>
              }
              save={save}
            />

            <UserSignature open={modalSignature} setOpen={setModalSignature} saveSignature={saveSignature} />
          </div>
        </>
      );
  };
  return (
    <div style={{ padding: 10, maxWidth: "700px", margin: "0 auto" }}>
      <Helmet>
        <title>SNAP Application</title>
      </Helmet>
      <Space>
        <img width={"100px"} src="https://static.wixstatic.com/media/527ab5_3da923e2d4b54105a899557487975301~mv2.jpg/v1/fill/w_466,h_466,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/SNAP-logo.jpg" />
        <h1>SNAP Application</h1>
      </Space>
      {renderFormBody()}
    </div>
  );
};

export default MassSNAP;
