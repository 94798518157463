// src/InstructionsPage.js
import React, { useEffect, useState } from "react";
import { Typography, Button, Alert, Card, Space, Steps, Image, Modal } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import developerStep1 from "./imgs/developermode/1.png";
import developerStep2 from "./imgs/developermode/2.png";

import ios1 from "./imgs/ios/1.png";
import ios2 from "./imgs/ios/2.png";
import ios3 from "./imgs/ios/3.png";
import ios4 from "./imgs/ios/4.png";
import ios5 from "./imgs/ios/5.png";

import alwaysAllow1 from "./imgs/alwaysallow/1.png";

const { Title, Paragraph, Link } = Typography;

const UserScriptInstall = () => {
  const [scriptCode, setScriptCode] = useState("");

  useEffect(() => {
    fetch("/userscript.txt")
      .then((response) => response.text())
      .then((data) => setScriptCode(data))
      .catch((error) => console.error("Error fetching the text file:", error));
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(scriptCode)
      .then(() => {
        alert("Script code copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  const steps_for_ios = (
    <>
      <Card>
        <Title level={4}>Steps for IOS</Title>
        <div>
          <a href="https://drive.google.com/file/d/1L5Zvdxs_2W3l_-ZhArjYks_LP8P3prwi/view?usp=sharing" target="_blank">
            Video Instructions 
          </a>
          <h5>Step 1: Download Stay App from App Store</h5>
          <h5>Step 2: And Create a new User Script</h5>
          <Image src={ios1} alt="Step 1" style={{ width: "100px" }} />

          <h5>
            Step 3: Copy and replace the code with this:{" "}
            <Button type="primary" icon={<CopyOutlined />} onClick={copyToClipboard}>
              Copy Script Code
            </Button>
          </h5>
          <Image src={ios2} alt="Step 1" style={{ width: "100px" }} />

          <h5>Step 4: Go to Safari and Click on AA</h5>
          <Image src={ios3} alt="Step 1" style={{ width: "100px" }} />

          <h5>Step 5: Click on manage extensions and turn access of Stay to on.</h5>
          <Image src={ios4} alt="Step 1" style={{ width: "100px" }} />
          {"=>"}
          <Image src={ios5} alt="Step 1" style={{ width: "100px" }} />

          <h6>Step 6: Refresh and click on AA and then on Stay. Click always allow. Refresh again.</h6>
        </div>
      </Card>
    </>
  );

  return (
    <div style={{ padding: 24 }}>
      <Title>Instructions to Install User Script</Title>
      <Button
        type="link"
        onClick={() => {
          Modal.info({
            okCancel: true,
            maskClosable: true,
            width: "80%",
            title: "Steps for the user script to work on IOS mobiles:",
            content: steps_for_ios,
          });
        }}
      >
        If you are on IOS, click here.
      </Button>
      {/* <Alert message="Note" description="Make sure to follow each step carefully to ensure the script works correctly." type="info" showIcon style={{ marginTop: 24 }} /> */}
      <Card>
        <Title level={4}>1. Install Tampermonkey on your browser</Title>
        <Paragraph>
          <Link href="https://www.tampermonkey.net/" target="_blank">
            Tampermonkey
          </Link>{" "}
          is available for most browsers.
          <br />
          <strong>If you're using a mobile or tablet, please install Firefox or Yandex first.</strong>
        </Paragraph>
      </Card>
      <Card>
        <Title level={4}>2. Activate this script</Title>
        <Paragraph>
          Click the link below to install the script directly:
          <br />
          <Link href="https://greasyfork.org/en/scripts/495168-link-health-user-script" target="_blank">
            AHD Chrome Extension
          </Link>
        </Paragraph>
        <Paragraph>
          Alternatively: If you encounter any issues, click the button below to show the code, then copy and paste it into a new script in Tampermonkey.{" "}
          <a href="https://drive.google.com/file/d/1q9UggEYnatYlR8v3krfpyWItM3eIdWHX/view?usp=sharing" target="_blank">
            See how?
          </a>
        </Paragraph>
        <Space>
          <Button type="primary" icon={<CopyOutlined />} onClick={copyToClipboard}>
            Copy Script Code
          </Button>
        </Space>
      </Card>
      <Card>
        <Title level={4}>3. Turn on developer mode in extensions (only for chrome)</Title>
        <div>
          <Paragraph>Please turn on the developer mode in the extensions tab.</Paragraph>
          <h4>Steps</h4>
          <div>
            <h5>Step 1: Open Extensions Tab</h5>
            <p>Go to the extensions tab in your browser.</p>
            <Image src={developerStep1} alt="Step 1" style={{ width: "100px" }} />
          </div>
          <div>
            <h5>Step 2: Enable Developer Mode</h5>
            <p>Toggle the Developer Mode switch to enable it.</p>
            <Image src={developerStep2} alt="Step 2" style={{ width: "100px" }} />
          </div>
        </div>
      </Card>

      <Card>
        <Title level={4}>4. Reload the webpage/ browser.</Title>
        <Paragraph>The script should work after reloading. If not, please report to admins.</Paragraph>
      </Card>

      <Card>
        <Title level={4}>5. Click "Always allow".</Title>
        <Paragraph>Tampermonkey will ask if you want to always allow to make call to this api: alistermartin.pythonanywhere.com/user-script.js</Paragraph>
        <Paragraph>Please click allow as this is where the original updated code is stored.</Paragraph>
        <Image src={alwaysAllow1} alt="Step 1" style={{ width: "100px" }} />
      </Card>
    </div>
  );
};

export default UserScriptInstall;
