import axios from "axios";
import { catcher } from "../../../../firebase/catcher";
import { getUserById } from "../../../../firebase/users/get";
import { getCurrentDate } from "../../../../helpers/time";
import { findItemInConfig } from "../config/document.config";
export function splitName(fullName) {
  // Split the full name into an array of words
  try {
    const parts = fullName.trim().split(/\s+/);

    // Extract the first name
    const firstName = parts[0];

    // Extract the last name
    const lastName = parts.length > 1 ? parts[parts.length - 1] : "";

    // Extract the middle name(s), if any
    let middleName = "";
    if (parts.length > 2) {
      middleName = parts.slice(1, parts.length - 1).join(" ");
    }

    // Return an object with the extracted names
    return {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
    };
  } catch (err) {
    return {};
  }
}

// Function to parse moreEntries in snapData
function parseMoreEntries(snapData) {
  return snapData.map((entry) => {
    if (entry.moreEntries) {
      try {
        // Attempt to parse the moreEntries string as JSON
        entry.moreEntries = JSON.parse(entry.moreEntries);
      } catch (error) {
        console.error("Error parsing moreEntries:", error);
      }
    }
    return entry;
  });
}

function transformData(originalData) {
  originalData = {
    ...originalData,
    ...splitName(originalData["name"]),
    date: getCurrentDate(),
    gender: originalData.gender?.[0].toLowerCase() === "m" ? true : false,
  };
  console.log("original data", originalData);
  const transformed = { snapData: parseMoreEntries(originalData.snapData) };
  Object.keys(originalData).forEach((key) => {
    if (key !== "snapData") {
      // Transforming and adding the property in the required {id, value} format
      transformed.snapData.push({ id: key, value: originalData[key] });
    }
  });

  let tmp = transformed.snapData; //its an array
  //converting more entries now
  let dat = [];
  tmp.forEach((item) => {
    const obj = JSON.parse(JSON.stringify(item));
    const config = findItemInConfig(item.id);
    // if (!config) return;
    obj.id = ["text", "date"].includes(config?.type ?? "text")
      ? item.id
      : item.id + "+" + item.value;

    dat.push(obj);
    if (item.value === false) return;
    item.moreEntries?.forEach((entry, I) => {
      const values = entry.map((x) => x.value); //values array
      config.more.questions.forEach((q, i) => {
        const subobj = { id: item.id + "+" + q.id, value: values[i], index: I };
        dat.push(subobj);
      });
    }); //array of array of values
  });
  console.log(dat);
  // copyArrayAsJsonToClipboard(transformed);
  dat = dat.map((x) => {
    if (!x.hasOwnProperty("value") || x.value === undefined) {
      x.value = "";
      // message.error("you havent filled " + x.id.replaceAll("+", "->"));
    }
    return x;
  });
  return dat;
}

export default async function generateSnapPdf(id) {
  catcher(async () => {
    const user_data = await getUserById(id);
    const data2Send = transformData(user_data);
    console.log(data2Send);

    // Notice the responseType: 'blob', this is crucial for handling binary data like PDFs
    const res = await axios.post(
      `${process.env.REACT_APP_BE}to-snap-pdf`,
      {
        user_data: data2Send,
      },
      {
        responseType: "blob", // This tells axios to expect a binary response instead of the default JSON
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "filled_snap.pdf"); // Or any other filename you wish to use
    document.body.appendChild(link);
    link.click(); // Simulate click on the link to start download
    link.parentNode.removeChild(link); // Clean up
  }).catch((error) => {
    console.error("Error generating PDF:", error);
  });
}
