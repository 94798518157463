import { Typography } from "antd";
import React, { useContext } from "react";
import MainContext, { mainContextConfig } from "../../contexts/mainContext";
import { IoLocation } from "react-icons/io5";
import { LiaOilCanSolid } from "react-icons/lia";
import useSize from "../use/useSize";
const logoContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#f0f0f0", // optional, for better contrast
};

const logoTextStyle = {
  display: "flex",
  alignItems: "center",
};

const iconStyle = {
  marginRight: "10px",
};

export const flagStyle = {
  marginLeft: "10px",
  marginRight: "10px",
  height: "50px", // adjust as needed
};

export default function HeadingContainer({ title }) {
  const { isMobile } = useSize();
  const TopLogo = ({ locConfig }) => (
    <div style={logoContainerStyle}>
      <Typography.Title style={logoTextStyle} level={isMobile ? 5 : 1}>
        Link Health ({locConfig?.title})
      </Typography.Title>
    </div>
  );

  const { loc } = useContext(MainContext);
  const locConfig = mainContextConfig["loc"][loc];
  return (
    <>
      <TopLogo locConfig={locConfig} />
    </>
  );
}
