
const colors = [
  "#FF5733", // Vibrant Orange
  "#DFC0CB", // Sky Blue
  "#2ECC71", // Fresh Green
  "#AF7AC5", // Soft Purple
  "#E74C3C", // Soft Red
  "#3498DB", // Light Blue
  "#F39C12", // Warm Peach
  "#8E44AD", // Dark Violet
  "#D7DDAA", // Dark Golden Grey
  "#F78DA7", // Soft Pink
  "#1ABC9C", // Turquoise
  "#2C3E50", // Dark Blue
  "#F1C40F", // Bright Yellow
  "#9B59B6", // Amethyst
  "#E67E22", // Carrot Orange
  "#D35400", // Pumpkin Orange
  "#34495E", // Wet Asphalt
  "#16A085", // Green Sea
  "#27AE60", // Nephritis
  "#2980B9", // Belize Hole
];

export const getColorByIndex = (index) => {
  return colors[index % colors.length];
};

export const applicationConfigs = {
  wic: {
    title: "WIC Submitted",
    uploaded_key: "mass_gov_wic_uploaded",
    summaryName: "Mass Gov WIC Applications",
    color: getColorByIndex(0),
  },
  liheap: {
    title: "Liheap Uploaded",
    uploaded_key: "liheap_uploaded",
    summaryName: "Liheap Applications",
    color: getColorByIndex(10),
  },
  texas_lifeline: {
    title: "Texas Lifeline Submitted",
    uploaded_key: "texas_uploaded",
    summaryName: "Texas Lifeline Applications",
    color: getColorByIndex(1),
  },
  dta_connect: {
    title: "TAFDC Program Submitted",
    uploaded_key: "dta_uploaded",
    summaryName: "TAFDC Program Applications",
    color: getColorByIndex(2),
  },
  kinderwait_intake: {
    title: "Kinder Wait Submitted",
    uploaded_key: "kinderwait_uploaded",
    summaryName: "Kinder Wait Applications",
    color: getColorByIndex(3),
  },
  snap: {
    title: "SNAP Submitted",
    uploaded_key: "snap_full_uploaded",
    summaryName: "SNAP Applications",
    color: getColorByIndex(4),
  },
  lifeline: {
    title: "LifeLine Submitted",
    uploaded_key: "lifeline_uploaded",
    summaryName: "LifeLine Applications",
    color: getColorByIndex(5),
  },
  liheap: {
    title: "Liheap Form Submitted",
    uploaded_key: "liheap_uploaded",
    summaryName: "Liheap Form Applications",
    color: getColorByIndex(6),
  },
  getyourrefund: {
    title: "GetYourRefund Submitted",
    uploaded_key: "getyourrefund_started",
    summaryName: "GetYourRefund Applications",
    color: getColorByIndex(7),
  },
  wic_texas: {
    title: "WIC-Texas Submitted",
    uploaded_key: "texas_wic_uploaded",
    summaryName: "WIC-Texas Applications",
    color: getColorByIndex(8),
  },
  medicare_uploaded: {
    title: "Medicare Submitted",
    uploaded_key: "medicare_uploaded",
    summaryName: "Medicare Savings Applications",
    color: getColorByIndex(9),
  },
};