import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./styles.css"; // Import the CSS file here
import { Input, message } from "antd";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(from, { replace: true });
    } catch (error) {
      console.error("Failed to sign in:", error.message);
      message.error("Username/Password incorrect!");
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        {/* <img src="logo.png" alt="Logo" className="login-logo" /> */}
        <h1>LOGIN</h1>
        <Input
          className="login-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <Input.Password
          className="login-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button disabled={loading} className="login-button" type="submit">
          {loading?"...logging in":"Submit"}
        </button>
      </form>
    </div>
  );
}

export default LoginPage;
