import { Typography } from "antd";
import React from "react";
const logoSrc = require("./link-health.webp");
const Loading = ({ height = "100vh", msg = "Loading" }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height,
      }}
    >
      <div
        style={{
          position: "relative",
          width: "250px",
          height: "250px",
        }}
      >
        <img
          src={logoSrc}
          alt="Loading"
          style={{
            width: "100%",
            height: "100%",
            animation: "spin 2s linear infinite",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "170px", // Adjust the size as needed
            height: "170px", // Adjust the size as needed
            borderRadius: "50%",
            backgroundColor: "white",
          }}
        ></div>
      </div>
      <Typography.Title level={3}>{msg}</Typography.Title>
      <style jsx>{`
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default Loading;
