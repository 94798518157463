import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,PieChart, Pie, Cell } from "recharts";
import { Button, DatePicker, Divider, Empty, Modal, Select, Space, Tabs, Tag, Typography } from "antd";
import moment from "moment";
import { applicationConfigs } from "../Home/home.config";
import { IoChevronDownOutline } from "react-icons/io5";
const { Option } = Select;
const { Text } = Typography;

const { RangePicker } = DatePicker

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28FC4', '#E91E63']

const generateEmptyDataForDateRange = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const data = [];
  let current = start;

  while (current.isSameOrBefore(end, "day")) {
    const date = current.format("YYYY-MM-DD");
    const entry = { date, day: current.date() };
    Object.keys(applicationConfigs).forEach((key) => {
      entry[key] = 0;
    });
    data.push(entry);
    current.add(1, "day");
  }
  return data;
};

const convertData = (users) => {
  const summary = {};
  users.forEach((user) => {
    const date = moment(user.createdOn).format("YYYY-MM-DD");
    if (!summary[date]) {
      summary[date] = { applications: 0 };
      Object.keys(applicationConfigs).forEach((key) => {
        summary[date][key] = 0;
      });
    }
    summary[date].applications += 1;
    Object.keys(applicationConfigs).forEach((key) => {
      if (user[applicationConfigs[key].uploaded_key]) {
        summary[date][key] += 1;
      }
    });
    summary[date]["ambassadorName"] = user.ambassadorName ? user.ambassadorName : "-"
  });

  return Object.keys(summary).map((date) => ({
    date,
    day: moment(date).date(),
    ...summary[date],
  }));
};

const filterDataByDateRange = (data, startDate, endDate,ambassadorOptions) => {
  console.log(ambassadorOptions)
  const filteredData = data.filter((item) => {
    const date = moment(item.date);
    if (ambassadorOptions == "all" || !ambassadorOptions){
      return date.isBetween(startDate, endDate, null, "[]");
    }else{
      // console.log(item)
      return date.isBetween(startDate, endDate, null, "[]") && item.ambassadorName == ambassadorOptions;
    }
  });

  console.log(filteredData)

  const filledData = generateEmptyDataForDateRange(startDate, endDate);
  filteredData.forEach((item) => {
    const index = filledData.findIndex((d) => d.date === item.date);
    if (index >= 0) {
      Object.keys(applicationConfigs).forEach((key) => {
        filledData[index][key] = item[key];
      });
      filledData[index].applications = item.applications;
    }
  });
  console.log(filledData)
  return filledData;
};

const getDataForDateRange = (data,year,month, config = null,ambassador="all") => {
  if (config) {
    // console.log(moment(config[0].toDate()))
    const start = moment(config[0].toDate());
    const end = moment(config[1].toDate());

    return filterDataByDateRange(data, start, end,ambassador);
  }
  const startOfMonth = moment(`${year}-${month}`, "YYYY-MM").startOf("month");
  const endOfMonth = moment(`${year}-${month}`, "YYYY-MM").endOf("month");
  // console.log(startOfMonth,endOfMonth)
  return filterDataByDateRange(data, startOfMonth, endOfMonth,ambassador);
  return []; // or any default handling
};

const ApplicationChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="day" />
      <YAxis />
      <Tooltip
        labelFormatter={(label, payload) => {
          if (payload && payload.length) {
            return moment(payload[0].payload.date).format("MMM D, YYYY");
          }
          return label;
        }}
      />
      <Legend />
      <Bar dataKey="applications" fill="#8884d8" />
    </BarChart>
  </ResponsiveContainer>
);
const ApplicationPieChart = ({ data }) => {
  // Transform the data object to an array
  const chartData = Object.keys(data).map(key => ({
    name: key.replace(/\"/g, ''), // Remove quotes from names
    value: data[key].applications,
    info: data[key].info || {},
  }));

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28FC4', '#E91E63']; // Custom colors for each segment

  if (Object.keys(data).length == 0){
    return (<>
    <Empty
    style={{padding:"30px 0px 0px 30px"}}
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{
      height: 60,
    }}
    description={
      <Typography.Text>
        No <a>Applications</a> found
      </Typography.Text>
    }
  >
  </Empty>
  </>)
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={chartData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
          label
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          content={({ active, payload }) => {
            // console.log(active,payload)
            if (active && payload && payload.length) {
              const { name, value, info } = payload[0].payload;
              return (
                <div className="custom-tooltip" style={{ backgroundColor: "white", border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }}>
                  <p className="label">{name}</p>
                  {Object.keys(info).map((key, index) => (
                    <p key={index} className="desc" style={{ color: COLORS[index % COLORS.length] }}>
                      {key}: {info[key]}
                    </p>
                  ))}
                  <Text style={{ fontSize: "15px" }} className="desc" mark>
                    <strong>Total Applications:</strong> {value}
                  </Text>
                </div>
              );
            }
            return null;
          }}
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};
const StackedApplicationChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="day" />
      <YAxis />
      <Tooltip
        // labelFormatter={(label, payload) => {
        //   if (payload && payload.length) {
        //     return moment(payload[0].payload.date).format('MMM D, YYYY');
        //   }
        //   return label;
        // }}
        formatter={(value, name, props) => {
          const total = props.payload ? Object.keys(applicationConfigs).reduce((sum, key) => sum + props.payload[key], 0) : 0;
          return name === "Total Applications" ? [total, name] : value !== 0 && value !== null ? [value, name] : null;
        }}
        content={(props) => {
          const { payload, label, active } = props;
          if (active && payload && payload.length) {
            const total = payload.reduce((sum, entry) => sum + entry.value, 0);
            return (
              <div className="custom-tooltip" style={{ backgroundColor: "white", border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }}>
                <p className="label">{moment(payload[0].payload.date).format("MMM D, YYYY")}</p>
                {payload.map(
                  (entry, index) =>
                    entry.value !== 0 && (
                      <p key={index} className="desc" style={{ color: entry.color }}>
                        {entry.name} : {entry.value}
                      </p>
                    )
                )}
                <Text style={{ fontSize: "15px" }} className="desc" mark>
                  <strong>Total Applications:</strong> {total}
                </Text>
              </div>
            );
          }
          return null;
        }}
      />
      <Legend />
      {Object.keys(applicationConfigs).map((key) => (
        <Bar key={key} dataKey={key} stackId="a" fill={applicationConfigs[key].color} name={applicationConfigs[key].title} />
      ))}
    </BarChart>
  </ResponsiveContainer>
);

const ApplicationDataChart = ({ users }) => {
  const [filter, setFilter] = useState(moment().format("YYYY-MM"));
  const [healthCenterDateFilter,setHealthCenterFilter] = useState(moment().format("YYYY-MM"));
  const [filteredData, setFilteredData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [chartType, setChartType] = useState("stacked");
  const [pieCustomRangeFlag, setPieCustomRangeFlag] = useState(false);
  const [pieCustomRange,setPieCustomRange] = useState(null)
  const [stackCustomRangeFlag, setStackCustomRangeFlag] = useState(false);
  const [stackCustomRange,setStackCustomRange] = useState(null)
  const [ambassadorsData,setAmbassadorsData] = useState([])
  const [ambassadorsFilter,setAmbassadorsFilter] = useState("all")

  const monthsOptions = Array.from({ length: 6 }, (_, i) => {
    const date = moment().subtract(i, "months");
    return { label: `${date.format("MMMM")} (${i === 0 ? "This month" : date.format("YYYY")})`, value: date.format("YYYY-MM") };
  });

  const ambassadorOptions = Array.from(new Set(users.map(user => user.ambassadorName)))
  .filter(name => name) // Filter out any undefined or null names
  .map(name => ({
    label: name,
    value: name
  }));

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };


  useEffect(() => {
    const convertedData = convertData(users);
  
    if (filter === "custom") {
      // Assuming stackCustomRange contains the custom date range
      if (stackCustomRange) {
        const dataForDateRange = getDataForDateRange(convertedData,0,0 ,stackCustomRange,ambassadorsFilter);
        setFilteredData(dataForDateRange);
      }
    } else {
      const [year, month] = filter.split("-");
      const dataForMonth = getDataForDateRange(convertedData, year, month,null,ambassadorsFilter);
      setFilteredData(dataForMonth);
    }
  }, [users, filter,ambassadorsFilter]);

  const handleChange = (value) => {
    if (value === "custom") {
      setStackCustomRangeFlag(true);
      setFilter("custom");
    } else {
      setStackCustomRangeFlag(false);
      setFilter(value);
  
      const convertedData = convertData(users);
      const [year, month] = value.split("-");
      const dataForMonth = getDataForDateRange(convertedData, year, month,null,ambassadorsFilter);
      setFilteredData(dataForMonth);
    }
  };

  const handleStackCustomRange = (dates) => {
    setStackCustomRange(dates);
    const convertedData = convertData(users);
    const dataForDateRange = getDataForDateRange(convertedData,0,0,dates,ambassadorsFilter);
    setFilteredData(dataForDateRange);
    // Use dataForDateRange as needed
  };

  const getApplicationsByHealthcare = (selectedYear =0, selectedMonth=0,config=null) => {
    const healthcareData = {};
    if (config){
      const startDate = moment({ year: config[0].year, month: config[0].month - 1, day: config[0].day });
      const endDate = moment({ year: config[1].year, month: config[1].month - 1, day: config[1].day });
      const filteredUsers = users.filter(user => {
        const createdOn = moment.unix(user.createdOn / 1000);
        return createdOn.isSameOrAfter(startDate) && createdOn.isSameOrBefore(endDate);
      });
      filteredUsers.forEach((user)=>{

        let ambassadorName = user.ambassadorName == "-"? "No HealthCare Center Selected" : user.ambassadorName || "No HealthCare Center Selected";
        
        if (!healthcareData[ambassadorName]) {
          healthcareData[ambassadorName] = { applications: 0, info: {} };
          Object.keys(applicationConfigs).forEach((key) => {
            healthcareData[ambassadorName].info[applicationConfigs[key].title] = 0;
          });
        }
        
        // Check each application config key
        Object.keys(applicationConfigs).forEach((key) => {
          if (user[applicationConfigs[key].uploaded_key]) {
            healthcareData[ambassadorName].applications += 1;
            healthcareData[ambassadorName].info[applicationConfigs[key].title] += 1;
          }
        });
      })
      console.log(healthcareData)
      return healthcareData
    }
    // Object to store the healthcare data
  
    users.forEach((user) => {
      // Convert the user's createdOn timestamp to a moment object
      const createdOn = moment.unix(user.createdOn / 1000);
      const year = createdOn.year();
      const month = createdOn.month() + 1; // moment months are 0-indexed
  
      // Only process users created in the selected month and year
      if (year === selectedYear && month === selectedMonth) {
        let ambassadorName = user.ambassadorName == "-"? "No HealthCare Center Selected" : user.ambassadorName || "No HealthCare Center Selected";
        
        if (!healthcareData[ambassadorName]) {
          healthcareData[ambassadorName] = { applications: 0, info: {} };
          Object.keys(applicationConfigs).forEach((key) => {
            healthcareData[ambassadorName].info[applicationConfigs[key].title] = 0;
          });
        }
  
        // Check each application config key
        Object.keys(applicationConfigs).forEach((key) => {
          if (user[applicationConfigs[key].uploaded_key]) {
            healthcareData[ambassadorName].applications += 1;
            healthcareData[ambassadorName].info[applicationConfigs[key].title] += 1;
          }
        });
      }
    });
    // console.log(healthcareData)
    return healthcareData;
  };

    useEffect(() => {
    const [year, month] = healthCenterDateFilter.split("-");
    const ambassadors = getApplicationsByHealthcare(parseInt(year),parseInt(month))
    setAmbassadorsData(ambassadors);
  }, [users, healthCenterDateFilter]);

  const handleSelectChange = (fullDate=null) => {
    if (fullDate == "custom"){
      setPieCustomRangeFlag(true)
      if (pieCustomRange){
        handleCustomRangeChange(pieCustomRange)
      }
    }else{
      setPieCustomRangeFlag(false)
      setHealthCenterFilter(fullDate)
      const [year, month] = fullDate.split("-");
      const ambassadors = getApplicationsByHealthcare(parseInt(year),parseInt(month))
      setAmbassadorsData(ambassadors)
    }
  }

  const handleChartTypeChange = (key) => {
    setChartType(key);
  };

  const totalApplications = Object.keys(applicationConfigs).reduce((acc, key) => {
    return acc + filteredData.reduce((sum, item) => sum + item[key], 0);
  }, 0);

  const handleCustomRangeChange = (dates) => {
    setPieCustomRange(dates)
    // console.log(dates)
    const formattedDates = dates.map(dateString => {
      // console.log(dateString)
      const date = new Date(dateString);
      // console.log(date)
      const day = date.getDate(); // Get day (UTC)
      const month = date.getMonth() + 1; // Get month (0-based, so +1, UTC)
      const year = date.getFullYear(); // Get year (UTC)
  
      return { day, month, year };
  });
  const ambassadors = getApplicationsByHealthcare(0,0,formattedDates)
  setAmbassadorsData(ambassadors)
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
        <div>
          <Space  direction="vertical" >
            <b>Month:</b>{" "}
            <Space>
            <Select defaultValue={filter} style={{ width: 200 }} onChange={handleChange}>
              {monthsOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
              <Option key={'custom'} value={'custom'}>
                Custom
              </Option>
            </Select>
            {stackCustomRangeFlag && (
            <RangePicker value={stackCustomRange} onChange={handleStackCustomRange} />
            )}
            </Space>
            {/* <b>Ambassador's Filter:</b>{" "}
            <Select defaultValue={ambassadorsFilter} value={ambassadorsFilter} style={{ width: 200 }} onChange={(val)=>{setAmbassadorsFilter(val)}}>
              <Option key="all" >
                All Applications
              </Option>
              {ambassadorOptions?.map((ambassador) => (
                <Option key={ambassador.value} value={ambassador.value}>
                  {ambassador.label}
                </Option>
              ))}
            </Select> */}
          </Space>
        </div>
        <Button type="link" onClick={showModal}>
          <Space>
            {" "}
            <h3>
              Total Applications: {totalApplications}
              <IoChevronDownOutline />
            </h3>
          </Space>
        </Button>
      </div>
      <StackedApplicationChart data={filteredData} />

      <Modal title={`Application Statistics for ${moment(filter).format("MMM, YYYY")}`} open={visible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <div style={{ marginTop: 20 }}>
          {Object.keys(applicationConfigs).map((key) => {
            const sum = filteredData.reduce((sum, item) => sum + item[key], 0);
            if (!sum) return;
            return (
              <Typography.Title level={4} key={key} style={{ marginBottom: 10, color: applicationConfigs[key].color }}>
                {applicationConfigs[key].title}: {sum}
              </Typography.Title>
            );
          })}
          <Typography.Title level={4} key={"total-applications"} style={{ marginBottom: 10, color: "black", padding: 10, border: "2px dashed darkgrey" }}>
            Total Applications: {totalApplications}
          </Typography.Title>
        </div>
      </Modal>
      <Divider  orientation="left"> HealthCare stats</Divider>
      <b>Month:</b>{" "}
      <Space>

      <Select defaultValue={healthCenterDateFilter} style={{ width: 200 }} onChange={handleSelectChange}>
        {monthsOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
        <Option key={'custom'} value={'custom'}>
          Custom
        </Option>
      </Select>
      {pieCustomRangeFlag && (
          <RangePicker value={pieCustomRange} onChange={handleCustomRangeChange} />
      )}
        </Space>
        <ApplicationPieChart data={ambassadorsData}/>
    </div>
  );
};

export default ApplicationDataChart;
