const excelList = [
  "id",
  "createdOn",
  "name",
  "age",
  "date_of_birth",
  "gender",
  "email",
  "address",
  "phone",
  "application_type",
  "preferred_language",
  "rent_or_housing_cost",
  "ssn_last_4",
  "number_in_household",
  "us_citizen",
  "preferred_communication",
  "massachusetts_ebt",
  "medical_expenses_elderly_disabled",
  "homeless",
  "permission_to_act_on_behalf",
  "travel_medical_care",
  "snap_benefits_outside_massachusetts",
  "other_income",
  "pay_shelter_costs",
  "disability_certified",
  "ethnicity",
  "training_program",
  "permission_to_contact",
  "permission_to_speak_dta",
  "adult_child_care_costs",
  "unpaid_internship",
  "disability",
  "pregnant",
  "in_kind_work",
  "travel_dependent_care",
  "pay_utlity_costs",
  "permission_for_ebt_shopping",
  "chind_under_5",
  "ssn",
  "worked_recently",
  "race",
  "volunteering",
  "consent_fb_pg",
  "snap_full_uploaded",
  "partial_liheap_email_sent",
  "partial_liheap",
  "lifeline_uploaded",
  "liheap_uploaded",
];
export default function getSortedList() {
  return excelList;
}
