import React, { useState } from "react";
import * as XLSX from "xlsx";
import getSortedList from "./config";
import { message } from "antd";
import moment from "moment";
import { convertToExcelDate } from "../../helpers/time";
import { flattenJson } from "../../helpers/obj";

// const [excelData,setExcelData] = useState(data);

const sortObjectByArray = (originalObjectList, orderArray) => {
  const sortedList = [];

  originalObjectList.forEach((originalObject) => {
    let sortedObject = {};
    let keysInOrder = new Set(orderArray);

    // Add properties in the specified order
    orderArray.forEach((key) => {
      if (originalObject.hasOwnProperty(key)) {
        sortedObject[key] = originalObject[key];
      }
    });

    // Add properties not mentioned in the order array
    Object.keys(originalObject).forEach((key) => {
      if (!keysInOrder.has(key)) {
        sortedObject[key] = originalObject[key];
      }
    });

    sortedList.push(sortedObject);
  });
  return sortedList;
};

export const convertDataToExcelData = (data) => {
  const allTempData = [];
  console.log(data);
  data.forEach((dt) => {
    const indData = flattenJson(dt);

    const tempData = {};
    for (let key in indData) {
      if (key != "signature") {
        if (indData[key]?.["value"]) {
          tempData[key] = indData[key]["value"];
          console.log(key);
        } else {
          if (indData[key] != null) {
            tempData[key] = indData[key];
            if (key === "createdOn") {
              console.log("hek");
              tempData[key] = convertToExcelDate(indData[key]);
            } else if (key === "date_of_birth") {
              tempData[key] = moment(indData[key], "DD-MM-YYYY").format(
                "DD/MM/YYYY"
              );
            }
          }
        }
      }
      // console.log(key)
    }
    allTempData.push(tempData);
  });
  return allTempData;
};

export const generateExcelLocally = (data) => {
  let excelData = convertDataToExcelData(data);
  let list = getSortedList();
  excelData = sortObjectByArray(excelData, list);
  // Convert JSON to worksheet
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate Excel file and trigger download
  XLSX.writeFile(
    workbook,
    moment().format("DD-MM-YY") + "-Patients Data from Web App.xlsx"
  );
  // message
  message.info("Excel report has been downloaded!");
};
