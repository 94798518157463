// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDbh71s-3hu9Ydf7_N6FplppIloINmHOl8",
  authDomain: "ahd-automation.firebaseapp.com",
  projectId: "ahd-automation",
  storageBucket: "ahd-automation.appspot.com",
  messagingSenderId: "953462984113",
  appId: "1:953462984113:web:4cdbe8394992dfca62c190"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db,storage };
