import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, Select, Radio, Checkbox, Button, Typography, Steps, message, DatePicker } from "antd";
import debounce from "lodash/debounce";
import "./form.styles.css";
import { catcher } from "../../../firebase/catcher";
import LocationAutocomplete from "../../../components/goog/LocationAutoComplete";

const { Option } = Select;
const { Step } = Steps;

const FieldRenderer = ({ field, form, displayOnly }) => {
  const { title, id, required, type, options, info, validate } = field;

  const getValidationRules = () => {
    let message = "This field is required";
    try {
      message = `Please add ${title.toLowerCase()}`;
    } catch (err) {}
    const rules = [{ required, message }];
    if (validate) {
      rules.push({
        validator: async (_, value) => {
          try {
            await validate(value);
          } catch (error) {
            throw new Error(error.message);
          }
        },
      });
    }
    return rules;
  };

  const formItemProps = {
    key: id,
    label: title,
    name: id,
    rules: getValidationRules(),
    style: { pointerEvents: displayOnly ? "none" : "initial" },
  };
  const itemProps = {
    border: displayOnly ? "none !important" : "initial",
  };

  switch (type) {
    case "heading":
      return (
        <Typography.Title level={3} key={id}>
          {title}
        </Typography.Title>
      );
    case "text":
      if (title === "Address" && !displayOnly) {
        return (
          <Form.Item {...formItemProps}>
            <LocationAutocomplete
              onInput={(address) => {
                form.setFieldsValue({ "QR~QID11~2": address });
              }}
              onComplete={(address, city, zip, county) => {
                console.log(address, city, zip, county);
                form.setFieldsValue({ "QR~QID11~2": address, "QR~QID11~3": city, QID2: zip });
              }}
            />
          </Form.Item>
        );
      }
      return (
        <Form.Item {...formItemProps}>
          <Input placeholder={info || title} {...itemProps} />
        </Form.Item>
      );
    case "textarea":
      return (
        <Form.Item {...formItemProps}>
          <Input.TextArea placeholder={info || title} {...itemProps} />
        </Form.Item>
      );
    case "select":
      return (
        <Form.Item {...formItemProps}>
          <Select {...itemProps} placeholder="Select an option" showSearch filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}>
            {options?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "radio":
      return (
        <Form.Item {...formItemProps}>
          <Radio.Group {...itemProps}>
            {options?.map((option) => (
              <Radio.Button key={option.value} value={option.value}>
                {option.text}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      );
    case "checkbox":
      if (options?.length > 0) {
        return (
          <Form.Item {...formItemProps}>
            <Checkbox.Group style={{ display: "flex", flexDirection: "column" }} {...itemProps}>
              {options?.map((option) => (
                <Checkbox key={option.value} value={option.value}>
                  {option.text}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
      } else {
        return (
          <Form.Item {...formItemProps} valuePropName="checked">
            <Checkbox {...itemProps}>{title}</Checkbox>
          </Form.Item>
        );
      }
    case "date":
      return (
        <Form.Item {...formItemProps}>
          <DatePicker {...itemProps} placeholder={info || title} format={field.date_format} />
        </Form.Item>
      );
    default:
      return null;
  }
};

const FormComponent = ({ formData, displayOnly = false, initialValues = {}, onSubmit, displayThanksOnSubmit = false, displaySteps = true }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(() => {
    const savedData = localStorage.getItem('wictexasdata');
    return savedData ? JSON.parse(savedData) : initialValues;
  });
  const [currentStep, setCurrentStep] = useState(0);

  const debouncedUpdateFormValues = useCallback(
    debounce((values) => {
      setFormValues((prevValues) => {
        const newValues = { ...prevValues, ...values };
        localStorage.setItem('wictexasdata', JSON.stringify(newValues));
        return newValues;
      });
    }, 300),
    []
  );

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [formValues, form]);

  const handleFormChange = (_, allValues) => {
    debouncedUpdateFormValues(allValues);
  };

  const handleNext = async () => {
    try {
      const currentFields = await form.validateFields();
      setFormValues((prevValues) => ({ ...prevValues, ...currentFields }));
      setCurrentStep(currentStep + 1);
    } catch (error) {
      message.error("Please fill out the required fields.");
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFinish = async () => {
    try {
      await catcher(
        async () => {
          const currentFields = await form.validateFields();
          const allValues = { ...formValues, ...currentFields };
          await onSubmit(allValues);
          if (displayThanksOnSubmit) {
            message.success("Form submitted successfully!");
          }
        },
        { setLoading }
      );
    } catch (error) {
      message.error("Please fill out the required fields.");
    }
  };

  const renderFormFields = () => {
    let steps = [];
    let currentPanel = [];
    let currentHeading = null;
    let panelIndex = 0;

    formData.forEach((field, index) => {
      if (!displaySteps && index > 0 && field.type === "heading") return;
      if (field.type === "heading") {
        if (currentHeading) {
          steps.push({
            title: currentHeading.title,
            content: currentPanel,
            key: panelIndex,
          });
          currentPanel = [];
          panelIndex++;
        }
        currentHeading = field;
      } else {
        currentPanel.push(<FieldRenderer key={field.id} field={field} form={form} displayOnly={displayOnly} />);
      }
    });

    if (currentHeading) {
      steps.push({
        title: currentHeading.title,
        content: currentPanel,
        key: panelIndex,
      });
    }

    return steps;
  };

  const steps = renderFormFields();

  return (
    <div>
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Step key={step.key} title={step.title} />
        ))}
      </Steps>
      <Form form={form} size="large" layout="vertical" onValuesChange={handleFormChange} initialValues={formValues} requiredMark={false} className="form">
        <div>{steps[currentStep].content}</div>
        <Form.Item>
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            {currentStep > 0 && (
              <Button onClick={handlePrevious} style={{ marginRight: "10px" }}>
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 ? (
              <Button onClick={handleNext} type="primary">
                Next
              </Button>
            ) : (
              <>
                {displayOnly ? null : (
                  // <Button>Ok</Button>
                  <Button type="primary" onClick={handleFinish} loading={loading}>
                    Submit
                  </Button>
                )}
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
