import React, { useState } from "react";
import { Input, Button } from "antd";

const NotesComponent = ({ initialNotes = "", onSave, isMobile }) => {
  const [notes, setNotes] = useState(initialNotes);
  const [saving, setSaving] = useState(false);
  const [currentInitialNotes, setCurrentInitialNotes] = useState(initialNotes); 

  const hasChanges = JSON.stringify(notes) !== JSON.stringify(currentInitialNotes);

  const handleSave = async () => {
    console.log(notes);
    setSaving(true);
    await onSave(notes === "" || !notes ? " " : notes); 
    setCurrentInitialNotes(notes); 
    setSaving(false);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleCancel = () => {
    setNotes(currentInitialNotes); // Revert to the last saved notes
  };

  return (
    <div style={{ padding: isMobile ? "10px" : "20px" }}>
      <Input.TextArea maxLength={1000} autoSize={{ minRows: 2 }} value={notes} onChange={handleNotesChange} placeholder="Notes..." style={isMobile ? { fontSize: "14px" } : { fontSize: "16px" }} />
      {hasChanges && (
        <div style={{ marginTop: "10px" }}>
          <Button size="small" style={{ marginRight: "2px" }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button size="small" type="primary" onClick={handleSave} disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotesComponent;
