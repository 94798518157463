import { Typography } from "antd";
import React from "react";

const ApplicationsConfig = () => {
  return (
    <>
      <Typography.Title level={4}>Applications config</Typography.Title>
      
    </>
  );
};

export default ApplicationsConfig;
