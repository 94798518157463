import { Divider, Typography } from "antd";

const contentMapping = {
  fullsnap: {
    name: {
      title: "Personal Information",
      text: null,
    },
    live_with_others: {
      title: "Household Information",
      text: "If others live with you, please complete the section below. Only list children under age 22, your spouse, and/or any other individuals who buy and prepare the majority of meals with you.",
    },
  },
  default: {
    us_citizen: {
      title: "Demographics Information",
      text: null,
    },
    permission_to_contact: {
      title: "Permission Options",
      text: null,
    },
  },
};

const renderTopContent = (formName, id) => {
  const formContent = contentMapping[formName]?.[id] || contentMapping.default[id];

  if (!formContent) return null;

  return (
    <>
      <Typography.Title style={{ color: "#034D36" }} level={3}>
        {formContent.title}
      </Typography.Title>
      {formContent.text && <Typography.Text level={4}>{formContent.text}</Typography.Text>}
      <Divider  />
    </>
  );
};

export { renderTopContent };
