import React from "react";

const ApplicationStatus = ({ user }) => {
  // Define statuses with corresponding render logic
  const statuses = [
    {
      key: "snap_full_uploaded",
      title: "SNAP",
      statuses: [
        {
          title: "Faxed",
          render: () => {
            return user.snap_full_faxed ? "✔️" : "❌";
          },
        },
      ],
    },
  ];

  return null;

  return (
    <div className="p-4 bg-white shadow rounded-md">
      {statuses.map(
        (app) =>
          user[app.key] && (
            <div key={app.key} className="mb-4">
              <h4 className="text-lg font-semibold text-gray-800 border-b pb-2 mb-3">
                {app.title.toUpperCase()}
              </h4>
              <div className="space-y-2">
                {app.statuses.map((status, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <span className="font-medium text-gray-600">
                      {status.title}:
                    </span>
                    <span
                      className={`${
                        status.render() === "✔️"
                          ? "text-green-500"
                          : "text-red-500"
                      } text-xl`}
                    >
                      {status.render()}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default ApplicationStatus;
