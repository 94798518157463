import languagesList from "languages-list";
import { calculateAge, getCurrentDate, normalizeDate } from "../../../../helpers/time";
import { splitName } from "../generateSnapPdf/get";
import { allRelations } from "./suggessions";
import { flattenJson, unflattenJson } from "../../../../helpers/obj";
import { Typography } from "antd";

export const systemgeneratedanswers = (data) => {
  let nameConfig;
  if (data.first_name?.value) {
    nameConfig = [
      {
        snap_id: "Last Name_pg1",
        value: data.last_name?.value,
        type: "text",
      },
      {
        snap_id: "First Name_pg1",
        value: data.first_name?.value,
        type: "text",
      },

      {
        id: "name",
        snap_id: "Signature",
        value: `${data.first_name?.value} ${data.last_name?.value}`,
        type: "text",
      },
    ];
  } else {
    const splitted = splitName(data["name"].value ?? "");
    nameConfig = [
      {
        snap_id: "Last Name_pg1",
        value: splitted.last_name,
        type: "text",
      },
      {
        snap_id: "First Name_pg1",
        value: splitted.first_name,
        type: "text",
      },
      {
        snap_id: "Middle Name_pg1",
        value: splitted.middle_name,
        type: "text",
      },
    ];
  }
  return [
    ...nameConfig,
    {
      snap_id: "Date",
      value: getCurrentDate(),
      type: "text",
    },
  ];
};
export const lifelinequestions = [
  {
    id: "name",
    question: "What is your name?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Signature",
  },
  {
    id: "application_type",
    question: "Do you have Medicaid, Medicare, SNAP, or none of these? (Medicaid = Masshealth)",
    type: "select",
    options: ["Medicaid", "Medicare", "SNAP", "none of the above"],
    validation: {
      required: true,
      error_message: "Please select an option.",
    },
    help_text: "Select the program you are currently enrolled in, if any. Select 'none of the above' if you are not enrolled in any listed programs.",
  },
  {
    id: "ssn_last4",
    question: "Last 4 numbers of Social Security Number. (SSN)",
    type: "text",
    validation: {
      regex: "^[0-9]{4}$",
      error_message: "Please enter the last 4 digits of your Social Security Number.",
    },
    min: 4,
    max: 4,
    help_text: "Enter the last 4 digits of your SSN as found on your Social Security card.",
  },
  {
    id: "date_of_birth",
    question: "What is your date of birth?",
    type: "date",
    snap_id: "Date of Birth",
    validation: {
      format: "YYYY-MM-DD",
      required: true,
      error_message: "Please enter your date of birth in the specified format",
    },
    help_text: "For example, if your birthday is July 4, 1976, enter 07/04/1974.",
  },

  {
    id: "gender",
    question: "What is your gender?",
    type: "select",
    options: ["M", "F", "O"],
  },
  {
    id: "address",
    question: "Address:",
    type: "text",
  },
  {
    id: "city",
    question: "City:",
    type: "text",
  },
  {
    id: "state",
    question: "State:",
    type: "text",
  },
  {
    id: "zip",
    question: "Zip Code:",
    type: "text",
  },
  {
    id: "email",
    question: "What is your email?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
  },
  {
    id: "phone",
    question: "What is your phone number?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Phone Number",
  },
];

export const wicQuestions = [
  {
    id: "name",
    question: "What is your name?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Signature",
  },
  // {
  //   id: "application_type",
  //   question: "Do you have Medicaid, Medicare, SNAP, or none of these? (Medicaid = Masshealth)",
  //   type: "select",
  //   options: ["Medicaid", "Medicare", "SNAP", "none of the above"],
  //   validation: {
  //     required: true,
  //     error_message: "Please select an option.",
  //   },
  //   help_text: "Select the program you are currently enrolled in, if any. Select 'none of the above' if you are not enrolled in any listed programs.",
  // },
  // {
  //   id: "ssn_last4",
  //   question: "Last 4 numbers of Social Security Number.",
  //   type: "text",
  //   validation: {
  //     regex: "^[0-9]{4}$",
  //     error_message: "Please enter the last 4 digits of your Social Security Number.",
  //   },
  //   help_text: "Enter the last 4 digits of your SSN as found on your Social Security card.",
  // },
  {
    id: "date_of_birth",
    question: "What is your date of birth?",
    type: "date",
    snap_id: "Date of Birth",
    // validation: {
    //   format: "YYYY-MM-DD",
    //   error_message: "Please enter your date of birth in the format: YYYY-MM-DD.",
    // },
    help_text: "For example, if your birthday is July 4, 1976, enter 1976-07-04.",
  },

  // {
  //   id: "gender",
  //   question: "What is your gender? (M/F/O)",
  //   type: "text",
  // },
  {
    id: "address",
    question: "Address:",
    type: "text",
  },
  {
    id: "city",
    question: "City:",
    type: "text",
  },
  // {
  //   id: "state",
  //   question: "State:",
  //   type: "text",
  // },
  {
    id: "zip",
    question: "Zip Code:",
    type: "text",
  },
  {
    id: "email",
    question: "What is your email?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
  },
  {
    id: "phone",
    question: "What is your phone number?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Phone Number",
  },

  {
    id: "preferred_language",
    question: "What language do you prefer to speak?",
    type: "select",
    options: ["English", "Spanish", "Cantonese", "Mandarin", "French", "Portuguese", "Russian", "Arabic", "Haitian Creole", "Other"],
    value: "English",
    snap_id: "What language do you prefer to speak",
  },

  {
    id: "best_time_to_contact",
    question: "Best time to contact you?",
    type: "multi-select",
    options: ["morning", "afternoon", "late afternoon", "evening", "saturday morning"],
    // extractedFromLiheap: true,
    // partial_liheap: true,
    // snap_id: "Phone Number",
  },
];

export const liheapExtractedDocumentConfig = [
  // {
  //   id: "name",
  //   question: "Full Name",
  //   type: "text",
  //   extractedFromLiheap: true,
  //   partial_liheap: true,
  //   snap_id: "Signature",
  // },

  {
    id: "first_name",
    question: "First Name",
    type: "text",
  },

  {
    id: "last_name",
    question: "Last Name",
    type: "text",
  },
  {
    id: "address",
    question: "Address",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Home Address_pg1",
  },
  {
    id: "phone",
    question: "Phone Number",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Phone Number",
  },
  {
    id: "email",
    question: "Email",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
  },
  {
    id: "age",
    question: "What is your age?",
    type: "text",
    type2: "number",
    extractedFromLiheap: true,
    not_required_in_snap: true,
  },
  {
    id: "gender",
    question: "Gender",
    type: "select",
    extractedFromLiheap: true,
    options: ["M", "F"],
    snap_id: (index, value) => {
      if (typeof value === "string" && value[0]?.toLowerCase() === "m") {
        return "Male";
      } else {
        return "Female";
      }
    },
  },
];
const documentConfig = [
  {
    id: "date_of_birth",
    question: "Date of birth",
    type: "date",
    snap_id: "Date of Birth",
  },
  {
    id: "preferred_language",
    question: "What language do you prefer to speak?",
    type: "select",
    options: ["English", "Spanish", "Cantonese", "Mandarin", "French", "Portuguese", "Russian", "Arabic", "Haitian Creole", "Other"],
    value: "English",
    snap_id: "What language do you prefer to speak",
  },

  {
    id: "us_citizen",
    question: "Are you a U.S. Citizen?",
    type: "boolean",
    snap_id: "Are you a U.S. Citizen?",
    value: true,
  },
  {
    id: "race",
    question: "Race:",
    type: "select",
    options: ["Black or African American", "American Indian or Alaska Native", "White", "Asian", "Native Hawaiian or Other Pacific Islander"],
    snap_id: (id, value) => {
      const mappings = {
        "Black or African American": "Black or African American",
        "American Indian or Alaska Native": "American Indian or Alaska Native",
        White: "White",
        Asian: "Asian",
        "Native Hawaiian or Other Pacific Islander": "Native Hawaiian or Other Pacific Islander",
      };
      return mappings[value];
    },
  },
  {
    id: "ethnicity",
    question: "Ethnicity:",
    type: "select",
    options: ["Hispanic or Latino", "Not Hispanic or Latino"],
    snap_id: (id, value) => {
      return value;
    },
  },
  {
    id: "homeless",
    question: "Are you homeless?",
    type: "boolean",
    snap_id: "Are you homeless?",
    value: false,
  },
  // {
  //   id: "child_under_5",
  //   question: "Do you have a child under 5 (for WIC)?",
  //   type: "boolean",
  //   value: false,
  // },

  {
    id: "disability",
    question: "Disability: Select your status from the dropdown menu",
    type: "text",
    suggessions: ["Physical", "Hearing", "Visual", "Intellectual/Cognitive", "Emotional/Mental Health", "None of the above"],
    snap_id: ["Other:", " Other"],
    value: "",
  },

  {
    id: "disability_certified",
    question: "Has Massachusetts certified that you have a disability?",
    type: "boolean",
    value: false,
  },

  // { id: "medicaid", question: "Do you have Medicaid?", type: "boolean" },
  {
    id: "massachusetts_ebt",

    question: "Do you have a Massachusetts Electronic Benefit Transfer (EBT) card?",
    type: "boolean",
    value: false,
    snap_id: "Do you have a Massachusetts Electronic Benefits Transfer (EBT) card?",
  },

  {
    id: "preferred_communication",
    question: "What is your preferred method of communication?",
    type: "select",
    value: "Telephone",
    options: ["In-Person", "Telephone", "Video Replay Services (VRS)"],
    snap_id: (index, value) => {
      return {
        "In-Person": " InPerson",
        Telephone: " Telephone",
        "Video Replay Services (VRS)": " Video Relay Services VRS",
      }[value];
    },
  },
  {
    id: "pregnant",
    question: "Are you pregnant?",
    type: "boolean",
    value: false,
  },

  // {
  //   id: "consent_fb_pg",
  //   type: "boolean",
  //   question: "Do you consent to receiving Federal Benefit program sign up navigation from the Link Health team?",
  //   value: true,
  // },
  {
    id: "ssn",
    question: "Social Security Number (SSN)",
    type: "text",
    snap_id: "Social Security Number",
    help_text2: "Privacy Notice: Your SSN is never stored and is sent directly to the state.",
  },
  {
    id: "live_with_others",
    question: "Do other people live with you?",
    snap_id: "Do you live with other people?",
    type: "boolean",
    value: false,
    more: {
      // ask more questions when the answer of the above question is= when
      when: true,
      limit: 4,
      questions: [
        {
          id: "name",
          question: "Name",
          type: "text",
          snap_id: (index) => {
            return `Household member name${index + 1}`;
          },
        },
        {
          id: "date_of_birth",
          question: "Date of Birth",
          type: "date", // Could be "date" if the system supports date formats
          snap_id: (index) => {
            return `Date of Birth${index + 1}`;
          },
        },
        {
          id: "social_security_number",
          question: "Social Security Number (SSN)",
          type: "text",
          snap_id: (index) => {
            return `Social Security Number${index + 1}`;
          },
        },
        {
          id: "relationship_to_applicant",
          question: "Relationship to you?",
          type: "text",
          suggessions: allRelations,
          snap_id: (index) => {
            return `Relationship to you${index + 1}`;
          },
        },
        {
          id: "us_citizen",
          question: "Is this person a US citizen?",
          type: "boolean",
          value: false,
          snap_id: (index) => {
            return `Is this person  U.S. Citizen${index + 1}?`;
          },
        },
        {
          id: "applying_for_snap",
          question: "Is this person applying for SNAP benefits?",
          type: "boolean",
          value: false,
          snap_id: (index) => {
            return `Is this person applying for SNAP benefits${index + 1}?`;
          },
        },
      ],
    },
  },
  {
    id: "worked_recently",
    question: "Has anyone worked in the last 60 days?",
    snap_id: "Has anyone worked in the last 60 days?",
    iftrue: "live_with_others", // render if true
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "person_with_income",
          question: "Person with Income",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: (index) => {
            return `Person with Income${index + 1}_pg4`;
          },
        },
        {
          id: "income_type",
          question: "Income Type",
          type: "text",
          suggessions: ["Wages", "Self-Employment"],
          snap_id: (index) => {
            return [`Other income type${index + 1}_pg4`, `Other income type${index + 1}`];
          },
        },
        {
          id: "employer",
          question: "Employer",
          type: "text",
          snap_id: (index) => {
            return `Employer${index + 1}`;
          },
        },
        {
          id: "income_frequency",
          question: "Frequency",
          suggessions: ["Weekly", "Biweekly"],

          type: "text", // This could specify the interval, e.g., weekly, bi-weekly, monthly
          snap_id: (index) => {
            return [`Other payment frequency${index !== 0 ? index + 1 : ""}_pg4`, `Other payment frequency${index + 1}`];
          },
        },
        {
          id: "gross_earnings_last_4_weeks",
          question: "Gross Earnings for Last 4 Weeks",
          type: "text", // Could be "number" if you prefer to capture numeric values
          snap_id: (index) => {
            return `First check_${index + 1}`;
          },
        },
      ],
    },
  },
  {
    id: "other_income",
    iftrue: "live_with_others",
    snap_id:
      "Does anyone receive any other type of income such as Unemployment Compensation, Child Support, Social Security, SSI, Workers’ Compensation, Veterans’ Benefits, Pensions or Rental Income?",
    question:
      "Does anyone receive any other type of income such as Unemployment Compensation, Child Support, Social Security, SSI, Workers’ Compensation, Veterans’ Benefits, Pensions, or Rental Income?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "person_with_income",
          question: "Person with Income",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: (index) => {
            return `Person with Other Type of Income Row${index + 1}_pg4`;
          },
        },
        {
          id: "income_type",
          question: "Income Type",
          type: "text",
          suggessions: ["Wages", "Self-Employment"],
          snap_id: (index) => {
            return `Other Income type Row${index + 1}_pg4`;
          },
        },
        {
          id: "income_frequency",
          question: "Frequency",
          suggessions: ["Weekly", "Biweekly", "Monthly"],
          type: "text", // This can be adapted to specify the interval e.g., weekly, bi-weekly, monthly
          snap_id: (index) => {
            return [`Other Row${index + 1}_pg4`, `Other payment frequency Row${index + 1}_pg4?`];
          },
        },
        {
          id: "gross_amount",
          question: "Gross Amount",
          type: "text", // Could be "number" if capturing numeric values is desired
          snap_id: (index) => {
            return `Gross amount Row${index + 1}_pg4`;
          },
        },
      ],
    },
  },
  {
    id: "pay_shelter_costs",
    iftrue: "live_with_others",
    question: "Is the household responsible to pay shelter costs?",
    snap_id: "Shelter costs",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 3,
      questions: [
        {
          id: "shelter_cost_details",
          question: "Provide details of your shelter costs:",
          type: "select",
          options: ["Rent", "Mortgage", "Property Taxes", "Home Insurance", "Condo fee", "Other"],
          snap_id: (index, value) => {
            return value + "_pg5";
          },
        },
        {
          id: "amount",
          question: "Amount",
          type: "text",
          placeholder: "$ _________",
          snap_id: (index, value, entries) => {
            console.log("==>row ", entries);
            const name = entries[0].value;
            const mappings = {
              Rent: "Rent",
              Mortgage: "Mortgage",
              "Property Taxes": "Property tax",
              "Home Insurance": "Home insurance",
              "Condo fee": "Condo fee",
              Other: "Other type of shelter cost",
            };
            return `${mappings[name]} amount`;
          },
        },
        {
          id: "frequency",
          question: "Frequency",
          type: "text",
          suggessions: ["Monthly", "Weekly", "Quarterly", "Annually", "Other"],
          snap_id: (index, value, entries) => {
            const name = entries[0].value;
            const mappings = {
              Rent: "rent",
              Mortgage: "mortgage",
              "Property Taxes": "property tax",
              "Home Insurance": "home insurance",
              "Condo fee": "condo fee",
              Other: "shelter cost",
            };
            return [`Other ${mappings[name]} payment frequency`, `${name}_frequency_other_pg5`];
          },
        },
      ],
    },
  },
  {
    id: "pay_utility_costs",
    question: "Is the household responsible to pay utility costs separate from shelter costs?",
    type: "boolean",
    value: false,
    snap_id: "Utility costs",
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "utility_service_options",
          question: "Select all utility and service options applicable to your household:",
          type: "multi-select",
          options: [
            "Heat (oil, gas, electricity or propane, etc.)",
            "Electricity and/or gas (other than heating use); water; sewage; and/or trash collection",
            "Electricity for an air conditioner in the summer",
            "A fee to use an air conditioner in the summer",
            "Phone or cell phone service (including pre-paid)",
          ],
          snap_id: (index, value) => {
            const ids = [];
            const mapping = {
              "Heat (oil, gas, electricity or propane, etc.)": "Heat oil gas electricity or propane etc",
              "Electricity and/or gas (other than heating use); water; sewage; and/or trash collection": "Electricity andor gas other than heating use",
              "Electricity for an air conditioner in the summer": "Electricity for an air conditioner in the summer",
              "A fee to use an air conditioner in the summer": "A fee to use an air conditioner in the summer",
              "Phone or cell phone service (including pre-paid)": "Phone or cell phone service including prepaid",
            };
            if (Array.isArray(value)) {
              value.forEach((e) => {
                ids.push(mapping[e]);
              });
            }
            return ids;
          },
        },
      ],
    },
  },
  {
    id: "adult_child_care_costs",
    iftrue: "live_with_others",
    question: "Is anyone responsible to pay for adult or child care costs?",
    snap_id: "Dependent Care Costs",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "name_of_child_or_dependent",
          question: "Name of Child or Dependent",
          type: "text",
          snap_id: (index) => {
            return `Row${index + 1}_name of dependent_pg6`;
          },
        },
        {
          id: "amount_paid",
          question: "Amount Paid",
          type: "text", // Use "number" if you prefer numeric input
          snap_id: (index) => {
            return `Row${index + 1}_amount_pg6`;
          },
        },
        {
          id: "payment_frequency",
          question: "Frequency",
          type: "text",
          snap_id: (index) => {
            return [`Row${index + 1}_frequency_other_pg6`, `Other dependent cost frequency${index + 1}`];
          },
        },
      ],
    },
  },
  {
    id: "travel_dependent_care",
    iftrue: "live_with_others",
    question: "Does anyone travel to and/or from a dependent care provider?",
    type: "boolean",
    value: false,
    snap_id: "Travel costs to and/or from dependent care provider",
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "name_of_medical_provider",
          question: "Name of Medical Provider",
          type: "text",
          snap_id: (index) => {
            return `Row${index + 1 + 4}_name of dependent_pg6`;
          },
        },
        {
          id: "address_of_medical_provider",
          question: "Address of Medical Provider",
          type: "text",
          snap_id: (index) => {
            return `Row${index + 1 + 4}_address of care provider_pg6`;
          },
        },
        {
          id: "number_of_car_trips_to",
          question: "Number of Car Trips per Week (TO the medical provider)",
          type: "text",
          snap_id: (index) => {
            return `Row${index + 1 + 4}_From your home to Care Provider for dependent${index + 1}_pg6`;
          },
        },
        {
          id: "number_of_car_trips_from",
          question: "Number of Car Trips per Week (FROM the medical provider)",
          type: "text",
          snap_id: (index) => {
            return `Row${index + 1 + 4}_From Care Provider to your home for dependent${index + 1}_pg6`;
          },
        },
        {
          id: "cost_for_parking_public_transportation",
          question: "Cost for Parking, Public Transportation, Taxi Cab, Shuttle, etc.",
          type: "text",
          snap_id: (index) => {
            return `If not driving: cost of public transportation for dependent${index + 1}_pg6`;
          },
        },
      ],
    },
  },
  {
    id: "medical_expenses_elderly_disabled",
    iftrue: "live_with_others",
    snap_id: "Medical Costs",
    question: "Does anyone who is at least 60 years old and/or disabled have out-of-pocket medical expenses?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 3,
      questions: [
        {
          id: "name",
          question: "Name",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: (index) => {
            return `Name${index + 1}`;
          },
        },
        {
          id: "expense_type",
          question: "Expense Type (Select all that apply)",
          type: "multi-select",
          options: ["Medical Care", "Dental Care", "Medications", "Health Insurance", "Other (over-the-counter medications, medical supplies, etc.)"],
          snap_id: (index, values) => {
            const ids = [];
            const mappings = {
              "Medical Care": "Medical Care",
              "Dental Care": "Dental Care",
              Medications: "Medications",
              "Health Insurance": "Health Insurance",
              "Other (over-the-counter medications, medical supplies, etc.)": "Other",
            };
            if (!Array.isArray(values)) return -1;
            values.forEach((value) => {
              ids.push(mappings[value] + Number(index + 1));
            });
            return ids;
          },
        },
        {
          id: "total_cost_per_month",
          question: "Total Cost Per Month",
          type: "text", // "number" if aiming for numerical values
          snap_id: (index) => {
            return `Total cost per month${index + 1}`;
          },
        },
      ],
    },
  },
  {
    id: "travel_medical_care",
    iftrue: "live_with_others",
    snap_id: "Does anyone travel to and/or from medical care such as a pharmacy, doctor, therapist, etc.)?",
    question: "Does anyone travel to and/or from medical care (such as a pharmacy, doctor, therapist, etc.)?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 3,
      questions: [
        {
          id: "name_of_medical_provider",
          question: "Name of Medical Provider",
          type: "text",
          snap_id: (index) => {
            return `Name${index + 1}_pg7`;
          },
        },
        {
          id: "address_of_medical_provider",
          question: "Address of Medical Provider",
          type: "text",
          snap_id: (index) => {
            return `Address of Medical Provider${index + 1}`;
          },
        },
        {
          id: "number_of_car_trips_to",
          question: "Number of Car Trips per Week (TO the medical provider)",
          type: "text", // Use "number" if you prefer numeric input
          snap_id: (index) => {
            return `To${index + 1}`;
          },
        },
        {
          id: "number_of_car_trips_from",
          question: "Number of Car Trips per Week (FROM the medical provider)",
          type: "text", // Use "number" if you prefer numeric input
          snap_id: (index) => {
            return `From${index + 1}`;
          },
        },
        {
          id: "cost_for_parking_public_transportation",
          question: "Cost for Parking, Public Transportation, Taxi Cab, Shuttle, etc.",
          type: "text", // Use "number" if you prefer numeric input
          snap_id: (index) => {
            return `Cost for Parking Public Transportation Taxi Cab Shuttle etc${index + 1}`;
          },
        },
      ],
    },
  },
  {
    id: "training_program",
    iftrue: "live_with_others",
    question: "Is anyone in a training program at least 80 hours per month?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "name_of_person_or_agency",
          question: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "If yes, who?1",
        },
      ],
    },
  },
  {
    id: "in_kind_work",
    iftrue: "live_with_others",
    question: "Is anyone working in exchange for goods and services (in-kind work)?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          question: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "If yes, who?3",
        },
      ],
    },
  },
  {
    id: "unpaid_internship",
    iftrue: "live_with_others",
    question: "Is anyone doing an unpaid internship?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          question: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "If yes, who?5",
        },
      ],
    },
  },
  {
    id: "volunteering",
    iftrue: "live_with_others",
    question: "Is anyone volunteering?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          question: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "If yes, who?7",
        },
      ],
    },
  },
  {
    id: "snap_benefits_outside_massachusetts",
    iftrue: "live_with_others",
    snap_id: "Has anyone received SNAP benefits outside of MA within past 30 days?",
    question: "Has anyone received SNAP benefits outside Massachusetts within the past 30 days?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          question: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "Has anyone recieved SNAP benefits outside Massachusetts within the past 30 days? If yes, who?",
        },
      ],
    },
  },
  {
    id: "permission_to_contact",
    snap_id: "Do you want to give us permission to contact a person or agency if we cannot reach you by phone?*",
    question: "Do you want to give us permission to contact a person or agency if we cannot reach you by phone?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          question: "Name of Person or Agency:",
          type: "text",
          snap_id: "Assisting with Applicatioin - Name of Person or Agency",
        },
        {
          id: "phone_number",
          question: "Phone Number:",
          type: "text",
          snap_id: "Assisting with Application - Phone Number",
        },
        {
          id: "address",
          question: "Address:",
          type: "text",
          snap_id: "Assisting with Application - Address",
        },
      ],
    },
  },
  {
    id: "permission_to_speak_dta",
    snap_id: "Do you want to give a person or agency permission to speak with DTA and get relevant confidential information about your case?*",
    question: "Do you want to give a person or agency permission to speak with DTA and get relevant confidential information about your case?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          question: "Name of Person or Agency:",
          type: "text",
          snap_id: "Authorization to Release Information - Name of Person or Agency",
        },
        {
          id: "phone_number",
          question: "Phone Number:",
          type: "text",
          snap_id: "Authorizaton to Release Information - Phone Number",
        },
        {
          id: "address",
          question: "Address:",
          type: "text",
          snap_id: "Authorization to Release Information - Address",
        },
      ],
    },
  },
  {
    id: "permission_to_act_on_behalf",
    snap_id: "Do you want to give an agency or someone you trust permission to sign forms, report changes, complete interviews, and talk about your case with us?*",
    question: "Do you want to give an agency or someone you trust permission to sign forms, report changes, complete interviews, and talk about your case with us?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          question: "Name of Person or Agency:",
          type: "text",
          snap_id: "Authorized Representative for Certification - Name of Person or Agency",
        },
        {
          id: "phone_number",
          question: "Phone Number:",
          type: "text", // Could specify format if needed
          snap_id: "Authorized Representative for Certification - Phone Number",
        },
        {
          id: "federal_employer_identification_number",
          question: "Federal Employer Identification Number (Agency Only):",
          type: "text", // This field is optional and specific to agencies
          snap_id: "Federal Employer ID Number - Agency Only",
        },
      ],
    },
  },
  {
    id: "permission_for_ebt_shopping",
    snap_id: "Do you want to give someone you trust permission to get an EBT card to food shop for you using your SNAP benefits?*",
    question: "Do you want to give someone you trust permission to get an EBT card to food shop for you using your SNAP benefits?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person",
          question: "Name of Person:",
          type: "text",
          snap_id: "Authorized Rep for EBT Transactions - Name of Person",
        },
        {
          id: "phone_number",
          question: "Phone Number:",
          type: "text", // Could specify format if needed
          snap_id: "Authorized Rep for EBT Transactions - Phone Number",
        },
        {
          id: "address",
          question: "Address:",
          type: "text",
          snap_id: "Authorized Rep for EBT Transactions - Address",
        },
      ],
    },
  },
];
export const timeFormat = "DD-MM-YYYY";
export default documentConfig;

const fullDocConfig = [...lifelinequestions, ...liheapExtractedDocumentConfig, ...documentConfig];
const findItemInConfig = (id) => {
  return fullDocConfig.find((item) => item.id === id);
};

export { findItemInConfig, fullDocConfig };

export function syncData(data) {
  // Ensure data is properly formatted before processing
  if (!data || typeof data !== "object") {
    throw new Error("Invalid data input");
  }

  // Normalize date of birth if available
  if (data.date_of_birth?.value) {
    try {
      data.date_of_birth.value = normalizeDate(data.date_of_birth.value);
      const ageValue = calculateAge(data.date_of_birth.value);
      if (ageValue) data.age = { value: ageValue };
    } catch (err) {
      console.error("Error normalizing date of birth:", err);
      delete data.date_of_birth;
    }
  }

  // Extract last 4 digits of SSN if available
  if (data.ssn?.value) {
    data.ssn_last4 = { value: data.ssn.value.slice(-4) };
  }

  // Construct full name if first and last names are available
  if (data.applicantFirstName?.value && data.applicantLastName?.value) {
    try {
      data.name = { value: `${data.applicantFirstName.value} ${data.applicantLastName.value}` };
    } catch (err) {
      console.error("Error constructing full name:", err);
    }
  }

  return data;
}
