import React from "react";
import { Input, Select, DatePicker, Image } from "antd";
import "./style.css";
import { beautifyKey } from "../../helpers/text";
import useSize from "../use/useSize";
const { Option } = Select;

const keys_only_for_localhost = ["ssn", "createdOn", "loc", "created_by_email"];

const ObjectDisplayer = ({ obj, config = {}, onUpdate }) => {
  const { isExtraSmall, isSmall } = useSize();
  const isMobile = isExtraSmall || isSmall;
  const handleChange = (key, value) => {
    const newObj = { ...obj };
    newObj[key] = value;
    onUpdate(newObj);
  };

  const renderVal = (key) => {
    try {
      const value = obj[key];
      if (key === "signature") {
        return <Image style={{ width: "300px" }} src={obj[key]} />;
      }
      if (value?.value === false) return "NO";
      if (value?.value === true) return "YES";
      let valToRender = value?.value ?? value;
      if (typeof valToRender === "string") return valToRender;
      if (!valToRender) return <i style={{ color: "grey" }}>Not entered.</i>;
      return JSON.stringify(valToRender);
      return <Input disabled value={value?.value} onChange={(e) => handleChange(e.target.value)} />;
    } catch (err) {}
  };

  return (
    <div>
      {Object.keys(obj).map((key) => {
        if (keys_only_for_localhost.includes(key)) return null;
        return (
          <div key={key} className="flex1" style={{ marginBottom: "10px" }}>
            <label
              className="label-obj-editor"
              style={{
                width: isMobile ? "60%" : "15%",
                textAlign: "left",
                fontWeight: 500,
                letterSpacing: "-0.3px",
              }}
            >
              {beautifyKey(key)}:{" "}
            </label>
            <div style={{ width: "75%" }}>{renderVal(key)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ObjectDisplayer;
