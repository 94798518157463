import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Checkbox, DatePicker, Input, InputNumber, Radio, Select, Space, Tooltip, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiTwotoneInfoCircle } from "react-icons/ai";
import Loading from "../../../components/Loading/Loading";
import { catcher } from "../../../firebase/catcher";
import { DATE_FORMAT as DATE_FORMAT_FOR_DISPLAY } from "../../../helpers/datetime";
import { getAllParamsFromLocation } from "../../../helpers/loc";
import documentConfig, { lifelinequestions, liheapExtractedDocumentConfig, timeFormat, wicQuestions } from "./config/document.config";
import "./form.css";
import { BsArrowLeft, BsQuestionCircle } from "react-icons/bs";
import { GrStarOutline } from "react-icons/gr";
import { MdErrorOutline, MdInfoOutline } from "react-icons/md";
import { normalizeDate } from "../../../helpers/time";
import { renderTopContent } from "./FormHelper";
import { saveUnfilledData } from "../../../firebase/applications/set_unfilled";
import { v4 as uuidv4 } from "uuid";
// is_public: form is publically served or not?
export default function FormQuestions({ user, type, save, onCancel, is_public = false, extras = null }) {
  const { id } = getAllParamsFromLocation();
  const [loading, setLoading] = useState(false);
  const [notPresentKeys, setNotPresentKeys] = useState([]);

  const [formData, setFormData] = useState(() => {
    let questions = [];
    if (type === "lifeline") {
      questions = lifelinequestions;
    } else if (type === "fullsnap") {
      questions = [...liheapExtractedDocumentConfig, ...documentConfig].filter((x) => !x.not_required_in_snap);
    } else if (type === "snap") {
      questions = documentConfig;
    } else if (type === "partial_liheap") {
      questions = liheapExtractedDocumentConfig.filter((question) => {
        if (question.partial_liheap) return true;
      });
    } else if (type === "wic") {
      questions = wicQuestions;
    }
    if (is_public) {
      questions = questions.map((q) => {
        delete q["value"];
        return q;
      });
    }
    let arr = JSON.parse(JSON.stringify(questions)).map((question) => {
      if (question.more) {
        // Initialize with one entry if `more` questions exist
        return {
          ...question,
          more: {
            ...question.more,
            entries: [Array(question.more.questions.length).fill({})], // Initialize with one set of 'more' questions
          },
        };
      }
      return question;
    });
    return arr;
  });
  useEffect(() => {
    catcher(
      async () => {
        let data = formData;
        if (is_public) {
          try {
            const old_data = JSON.parse(localStorage.getItem("formData2"));
            if (Array.isArray(old_data)) {
              data = old_data;
            }
          } catch (err) {
            console.error(err);
          }
        }
        setFormData(
          data.map((item) => {
            const obj = { ...item }; // Creating a shallow copy of the item
            const docSaved = user[obj.id];
            if (docSaved) {
              obj.value = typeof docSaved === "string" ? docSaved : docSaved.value;
              if (docSaved.moreEntries) {
                obj.more = { ...obj.more, entries: JSON.parse(docSaved.moreEntries) };
              }
            }

            return obj;
          })
        );
      },
      { loading, success_msg: "" }
    );
  }, [id]);
  // saving data in form
  useEffect(() => {
    if (is_public && formData[0].value) localStorage.setItem("formData2", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    if (!is_public) return;
    let uniqueId = localStorage.getItem("unfilledId");

    if (!uniqueId) {
      uniqueId = uuidv4();
      localStorage.setItem("unfilledId", uniqueId);
    }

    const intervalId = setInterval(() => {
      saveUnfilledData(uniqueId, formData);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [formData]);

  const saveForm = () => {
    //checks
    console.log("formData**", formData);
    for (let i = 0, len = formData.length; i < len; i++) {
      const obj = formData[i];
      if (!obj.hasOwnProperty("value") || obj["value"] === null || obj["value"] === "") {
        setNotPresentKeys([obj?.id]);
        return message.error("Please fill all the fields in order to submit the form!");
      }
    }
    setNotPresentKeys([]);
    const dataToBeSaved = formData.map((f) => {
      const obj = { id: f.id, value: f.value };
      if (f.more?.entries) {
        obj.moreEntries = JSON.stringify(f.more?.entries);
      }
      return obj;
    });
    catcher(
      async () => {
        console.log("dataToBeSaved", dataToBeSaved);
        await save(dataToBeSaved);
      },
      { success_msg: "", setLoading }
    );
  };

  const handleInputChange = (questionId, value, entryIndex = null, questionIndex = null) => {
    setFormData((prevFormData) =>
      prevFormData.map((question) => {
        if (question.id === questionId) {
          if (entryIndex !== null && questionIndex !== null && question.more) {
            // Update 'more' entries
            const updatedEntries = question.more.entries.map((entry, eIndex) =>
              eIndex === entryIndex ? entry.map((subQuestion, qIndex) => (qIndex === questionIndex ? { ...subQuestion, value } : subQuestion)) : entry
            );
            return {
              ...question,
              more: {
                ...question.more,
                entries: updatedEntries,
              },
            };
          } else {
            // Update regular question value
            return { ...question, value };
          }
        }
        return question;
      })
    );
  };

  const addMoreEntry = (id) => {
    setFormData((prevFormData) =>
      prevFormData.map((question) => {
        if (question.id === id && question.more && question.more.entries.length < question.more.limit) {
          return {
            ...question,
            more: {
              ...question.more,
              entries: [...question.more.entries, Array(question.more.questions.length).fill({})], // Add a new set of 'more' questions
            },
          };
        }
        return question;
      })
    );
  };

  const removeMoreEntry = (id, entryIndex) => {
    if (window.confirm("confirm remove entry?"))
      setFormData((prevFormData) =>
        prevFormData.map((question) => {
          if (question.id === id && question.more && question.more.entries.length > 1) {
            return {
              ...question,
              more: {
                ...question.more,
                entries: question.more.entries.filter((_, index) => index !== entryIndex),
              },
            };
          }
          return question;
        })
      );
  };

  const renderQuestionInput = (question, value, handleChange) => {
    const commonConfig = {
      value: value,
      onChange: handleChange,
      size: "large",
    };
    switch (question.type) {
      case "text":
        if (question.s_path) {
          const s_path = question.s_path;
          const doc = formData.find((f) => f.id === s_path[0]);
          question.suggessions = doc.more.entries?.map((arr) => arr[s_path[1]].value);
        }
        if (question.suggessions) {
          return (
            <AutoComplete
              {...commonConfig}
              style={{ width: question.more ? "100%" : "100%" }}
              options={question.suggessions?.map((q) => ({ value: q }))}
              filterOption={(inputValue, option) => option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1}
            />
          );
        }
        return <Input size="large" {...commonConfig} minLength={question.min} maxLength={question.max} />;
      case "date":
        return (
          <div>
            <div style={{ fontSize: "small", color: "grey" }}>Please use this format: {DATE_FORMAT_FOR_DISPLAY}</div>
            <DatePicker
              {...commonConfig}
              allowClear={false}
              style={{ width: "100%" }}
              format={DATE_FORMAT_FOR_DISPLAY}
              value={value ? dayjs(value, timeFormat) : null}
              onChange={(x, y) => {
                handleChange(moment(y, DATE_FORMAT_FOR_DISPLAY).format(timeFormat));
              }}
            />
            {value && <i style={{ color: "grey" }}>= {moment(normalizeDate(value, false), timeFormat).format("MMMM DD, YYYY")}</i>}
          </div>
        );
      case "boolean":
        return (
          <Radio.Group {...commonConfig}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        );
      case "select":
        return (
          <Select {...commonConfig} style={{ width: "100%" }}>
            {question.options.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        );
      case "multi-select":
        return (
          <Checkbox.Group
            {...commonConfig}
            options={question.options.map((option) => ({
              label: option,
              value: option,
            }))}
          />
        );
      default:
        return null;
    }
  };

  const renderMoreEntries = (question) => {
    const entriesLimitExceeded = question.more.entries.length < question.more.limit;
    return (
      <div className="entriesDiv">
        {question.more.entries.map((entry, entryIndex) => (
          <div key={entryIndex} className="entryDiv">
            <div className="entryHeader">
              {question.more.entries.length > 1 ? <b>Entry {entryIndex + 1}</b> : ""}
              {question.more.entries.length > 1 && (
                <Button className="removeButton" onClick={() => removeMoreEntry(question.id, entryIndex)}>
                  <MinusCircleOutlined /> Remove
                </Button>
              )}
            </div>
            <div className="entryContent">
              {entry.map((subQuestion, questionIndex) => {
                return (
                  <div key={questionIndex} className="entryQuestion">
                    <div className="label">
                      <b style={{ fontWeight: "500" }}>{question.more.questions[questionIndex].question}</b>
                    </div>
                    <div className="answer">
                      {renderQuestionInput(question.more.questions[questionIndex], subQuestion.value, (e) => handleInputChange(question.id, e.target ? e.target.value : e, entryIndex, questionIndex))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        {/* <Button className="addButton" onClick={() => addMoreEntry(question.id)}>
          Add Entry
        </Button> */}
        {entriesLimitExceeded && (
          <Button size="small" type="primary" icon={<PlusOutlined />} onClick={() => addMoreEntry(question.id)}>
            Add another entry
          </Button>
        )}
      </div>
    );
  };

  const renderQuestions = () => {
    return formData.map((question, index) => {
      if (question.iftrue) {
        const formWhoseIfTrueRequired = formData.find((f) => f.id === question.iftrue);
        // console.log(
        //   question,
        //   "iftrue found!",
        //   formData.find((f) => f.id === question.iftrue)
        // );
        if (!formWhoseIfTrueRequired.value) return (question.value = false);
      }
      return (
        <div key={index}>
          {renderTopContent(type, question.id)}
          {notPresentKeys?.includes(question.id) && (
            <div style={{ color: "red", fontWeight: 500, fontSize: "smaller" }}>
              <MdErrorOutline /> Please fill this
            </div>
          )}

          <div className="question">
            <div className="label" style={{ display: "flex", alignItems: "center", gap: 0, justifyContent: "space-between" }}>
              <Tooltip color="blue" title={question.help_text}>
                {question.question} {question.help_text && <BsQuestionCircle />}
              </Tooltip>

              {/* <QuestionExtras question={question} /> */}
            </div>
            <div style={{ fontSize: "small", color: "grey", alignSelf: "flex-start" }}>
              {question.help_text2 && (
                <div>
                  <MdInfoOutline /> {question.help_text2}
                </div>
              )}
            </div>
            <div className="answer">{renderQuestionInput(question, question.value, (e) => handleInputChange(question.id, e.target ? e.target.value : e))}</div>
          </div>

          {question.value === true && question.more && <>{renderMoreEntries(question)}</>}
        </div>
      );
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Here you would handle form submission, e.g., posting data to a server.
  };
  if (loading) return <Loading />;

  return (
    <form onSubmit={handleSubmit} className="form">
      {renderQuestions()}
      {extras}
      <Space style={{ width: "100%", justifyContent: "flex-end" }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button loading={loading} type="primary" htmlType="submit" onClick={saveForm}>
          Submit
        </Button>
      </Space>
    </form>
  );
}
