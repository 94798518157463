import React, { useState } from "react";
import FormComponent from "../FormComponent/FormComponent";
import { wicTexasConfig } from "./config";
import LocationAutocomplete from "../../../components/goog/LocationAutoComplete";
import ThankYouMessage from "../../../components/UI/ThankyouForSubmitting";
import { createUser } from "../../../firebase/users/set";
import { cleanObject } from "../../../helpers/obj";
import moment from "moment";
import useSize from "../../../components/use/useSize";

const WicTexasForm = () => {
  const { isMobile } = useSize();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (formData) => {
    const data = { ...formData };
    if (!Array.isArray(data.consent) || !data.consent[0]) throw "Consent";
    wicTexasConfig.forEach((item) => {
      if (data[item.id]) {
        item.other_ids?.forEach((other_id) => {
          data[other_id] = data[item.id];
        });
      }
    });

    await createUser(
      cleanObject({
        ...data,
        loc: "tx",
        input_by_user: true,
        form_name: "wic_texas",
        date_of_birth: moment(data.date_of_birth, "MM/DD/YYYY")?.format("DD-MM-YYYY"),
        createdOn: new Date().getTime(),
        name: data?.firstName + " " + data?.lastName,
        texas_wic_uploaded: true,
      })
    );
    console.log("Form data:", data);
    setIsSubmitted(true);
  };

  return (
    <>
      {
        <div style={{ maxWidth: "1000px", margin: "0 auto", boxShadow: "rgba(0, 0, 0, 0.14) 0px 3px 8px" }}>
          <header style={{ height: "80px", background: "#09A6AB", display: "flex", color: "white" }}>
            <img src="https://www.cityoflaredo.com/home/showpublishedimage/858/638107725397770000" style={{ maxHeight: "100%", display: isMobile ? "none" : "initial" }} alt="WIC texas" />{" "}
            <h1 className="notranslate" style={{marginLeft:"10px"}}>{"   "}WIC Texas Application</h1>
          </header>
          <main style={{ padding: 20 }}>
            {/* <h2>Texas Wic Form</h2> */}
            {/* <LocationAutocomplete /> */}
            {isSubmitted ? (
              <ThankYouMessage />
            ) : (
              <>
                <p style={{ color: "darkblue", fontSize: "larger" }}>Submit this application to get WIC benefits in Texas.</p>
                <FormComponent initialValues={{}} formData={wicTexasConfig} onSubmit={handleSubmit} />
              </>
            )}
          </main>
        </div>
      }
    </>
  );
};

export default WicTexasForm;
