import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../config";
import isAdmin, { isAmbassador, isFellow, isRemoteAmbassador } from "./config";
import { updateUser } from "./set"; // Ensure this import is used if needed, otherwise it can be removed

export const accessConfig = {
  "remoteambassador@ahealthierdemocracy.org": {
    accessHours: 99999999,
  },
  "fellow@ahealthierdemocracy.org": {
    accessHours: 99999999,
  },
  "admin@ahealthierdemocracy.org": {
    accessHours: 99999999,
  },
  "ambassador@ahealthierdemocracy.org": {
    accessHours: 0,
  },
};

export const getUserAccess = () => {
  return accessConfig[auth.currentUser.email];
};

function isLessThanHours(createdOn, xHours) {
  if (!createdOn) return false;
  const now = Date.now();
  const millisec = xHours * 60 * 60 * 1000;
  return now - createdOn < millisec;
}

export async function getAllUsers(loc = "ma") {
  const accessHours = getUserAccess()?.accessHours;
  if (!accessHours) return [];
  const userRef = collection(db, "User");

  let q = query(userRef, where("loc", "==", loc));

  const snapshot = await getDocs(q);
  const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  return users.filter((user) => {
    const createdOn = user?.createdOn?.value ?? user?.createdOn;
    if (isLessThanHours(createdOn, accessHours)) {
      return true;
    }
    return false;
  });
}

export async function getUserById(userId) {
  const userDocRef = doc(db, "User", userId);
  const docSnap = await getDoc(userDocRef);

  if (docSnap.exists()) {
    const user = { id: docSnap.id, ...docSnap.data() };
    const createdOn = user?.createdOn?.value ?? user?.createdOn;

    // if (isLessThanHours(createdOn, getUserAccess()?.accessHours ?? 100)) {
    return user;
    // }
    return false;
  }
}
